import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import axios from 'axios';
import { EditorState, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { React, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Header from '../header/Header';
import CustomLogger from '../logger/Logger';
import MenuButtons from '../menubuttons/MenuButtons';

export default function Question() {
    //　Log　in画面から必要情報を受け取る
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, notificationCount, notifications, userData } = location.state || {};
    const [cookies, setCookie, removeCookie] = useCookies();
    const [title, setTitle] = useState('')
    const [newToken, setNewToken] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [newFileName, setNewFileName] = useState(null);
    const logger = new CustomLogger();
    const [isTextLongEnough, setIsTextLongEnough] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [editorContent, setEditorContent] = useState('');
    const [plainTextContent, setPlainTextContent] = useState('');
    const [loadingMessage, setLoadingMessage] = useState("");
    const [isAnonymous, setIsAnonymous] = useState(false);
    const customButtonList = [
        ['undo', 'redo'],
        ['fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image'],
    ];
    const fileInputRef = useRef(null);
    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    // 画面が再描画される度にカテゴリを取得するようにする
    useEffect(() => {
        console.log('カテゴリを取得します')
        async function fetchCategory() {
            const res = await axios.post(
                `/backend/getcategories`,
                {
                    withCredentials: true
                }
            );
            console.log(res)
            setCategories(res.data);
        }
        fetchCategory();
    }, []);

    // チェックボックスの変更を処理します
    const handleTagChange = (event) => {
        const tag = event.target.value;
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((s) => s !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };
    // チェックボックスの削除を処理します
    const handleDelete = (tagToDelete) => {
        setSelectedTags(selectedTags.filter((tag) => tag !== tagToDelete));
    };

    // HTMLからプレーンテキストを抽出する関数
    function extractTextFromHtml(html) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    // エディタの内容を処理する関数
    const handleEditorChange = (content) => {
        setEditorContent(content);

        // エディタ内の画像URLを抽出
        const editorImageUrls = content.match(/<img [^>]*src="([^"]+)"[^>]*>/g)?.map(imgTag => {
            const match = imgTag.match(/src="([^"]+)"/);
            return match ? match[1] : '';
        }) || [];

        console.log('エディタ内の画像URL:', editorImageUrls);

        // selectedImagesからエディタ内にない画像を削除
        setSelectedImages(prevImages => prevImages.filter(img => editorImageUrls.includes(img.url)));

        // HTMLからプレーンテキストを抽出
        const plainText = extractTextFromHtml(content);
        setPlainTextContent(plainText);

        // プレーンテキストの長さを確認し、ステートを更新
        setIsTextLongEnough(plainText.length >= 10);
    };

    // react-draft-wysiwygで入力した内容を取得する関数
    const getContent = (editorState) => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const markup = stateToHTML(contentState);
        return {
            raw: JSON.stringify(rawContentState),
            markup: markup
        };
    }

    // 入力されたブロックをjsonとして受け取る
    const getTextBlocksAsJson = () => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const textBlocks = rawContentState.blocks.map(block => block.text);
        return textBlocks.join('');
    };
    // ファイル選択時のハンドラ
    const handleFileSelect = (event) => {
        // 選択された新しいファイルのリストを取得
        const newFilesArray = Array.from(event.target.files);
        console.log(newFilesArray);
        // 既存のファイルリストに新しいファイルを追加
        setSelectedFiles(prevFiles => [...prevFiles, ...newFilesArray]);
    };
    // ファイル削除時のハンドラ
    const handleRemoveFile = (fileName) => {
        setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));
    };
    // ファイルリストの表示
    const renderFileList = () => {
        if (!selectedFiles || selectedFiles.length === 0) {
            return <div style={{ marginTop: '10px' }}>No files selected.</div>;
        }
        return (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {selectedFiles.map((file, index) => (
                    <li key={index} style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
                        <span style={{ flexGrow: 1, marginRight: '10px' }}>{file.name}</span>
                        <button
                            onClick={() => handleRemoveFile(file.name)}
                            style={{
                                border: 'none',
                                background: '#990000',
                                color: 'white',
                                padding: '5px 10px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease'
                            }}
                            onMouseOver={(e) => e.target.style.backgroundColor = '#bb3333'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#990000'}
                        >削除
                        </button>
                    </li>
                ))}
            </ul>
        );
    };

    // 入力された質問をサーバーを介してDBに保存する
    async function PostQuestion() {
        setLoading(true); // ローディング開始
        const { raw, markup } = getContent(editorState);
        const textBlocksJson = getTextBlocksAsJson();
        let questionId = 0
        let imageFiles = [];
        let otherFiles = [];

        // selectedFilesが存在する場合のみファイルアップロード処理を実行
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;

        // 画像ファイルのアップロード
        setLoadingMessage("画像ファイルをアップロード中...");
        if (selectedImages && selectedImages.length > 0) {
            for (let imageObj of selectedImages) {
                const file = imageObj.file;
                const fileName = `${userId}_${formattedDate}_${file.name}`;
                const formData = new FormData();
                formData.append('file', file, fileName);

                try {
                    await axios.post(`/backend/uploadfile`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                    imageFiles.push(fileName);
                } catch (error) {
                    console.error('Image upload failed:', error);
                }
            }
        }

        // その他のファイルのアップロード
        setLoadingMessage("その他のファイルをアップロード中...");
        if (selectedFiles && selectedFiles.length > 0) {
            for (let file of selectedFiles) {
                const fileName = `${userId}_${formattedDate}_${file.name}`;
                const formData = new FormData();
                formData.append('file', file, fileName);

                try {
                    await axios.post(`/backend/uploadfile`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                    otherFiles.push(fileName);
                } catch (error) {
                    console.error('File upload failed:', error);
                }
            }
        }
        //DBに質問を登録してページ遷移
        setLoadingMessage("質問をデータベースに登録中...");
        console.log('質問を投稿します...');
        console.log('ファイル:', imageFiles);
        console.log('その他のファイル:', otherFiles);
        try {
            const res = await axios.post(`/backend/question`,
                {
                    question: plainTextContent,
                    title: title,
                    markup: editorContent,
                    userId: userId,
                    selectedTags: selectedTags,
                    imageFiles: imageFiles,
                    otherFiles: otherFiles,
                    isAnonymous: isAnonymous,
                    withCredentials: true
                })
            console.log(res)

            if (res.data && res.data.id) {
                questionId = res.data.id

                // 通知テーブルにデータを登録
                console.log('通知テーブルにデータを登録します...');
                const notificationRes = await axios.post(`/backend/notifications`,
                    {
                        userId: userId,
                        title: title,
                        questionId: questionId,
                        withCredentials: true
                    })
                if (!notificationRes.data.success) {
                    console.error('Error during the notification registration:', notificationRes.data);
                }
                // ここで画面遷移
                console.log('回答画面に移動します');
                navigate(
                    '/answer', {
                    state: {
                        userId: userId,
                        questionId: questionId,
                        notificationCount: notificationCount,
                        notifications: notifications,
                        userData: userData,
                    },
                    replace: true,
                }
                )
            } else {
                console.log(res.data)
            }
        } catch (error) {
            logger.error('データ送信中にエラーが発生しました:', error);
            setErrorMessage("データ送信中にエラーが発生しました。再度試してください。");
        } finally {
            setLoading(false); // ローディング終了（成功または失敗）
            setLoadingMessage("");
        }
    };

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '4px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={categories}
                />
                <MenuButtons
                    navigate={navigate}
                    userId={userId}
                    buttonType={''}
                    notificationCount={notificationCount}
                    notifications={notifications}
                    userData={userData}
                    categories={categories}
                />
            </Box>

            <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{ flexGrow: 1, backgroundColor: '#F2F2F2', height: "100vh" }}>
                {loading && (
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        padding: '30px',
                        backgroundColor: '#f8f8f8',
                        borderRadius: '12px',
                        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <CircularProgress style={{ animationDuration: '550ms' }} />
                        <p style={{
                            marginTop: '15px',
                            fontSize: '18px',
                            fontWeight: '500',
                            color: '#333',
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                        }}>
                            {loadingMessage}
                        </p>
                    </Box>
                )}
                <Grid container item xs={12} sm={10} md={8} justifyContent="center" alignItems="center">
                    <Box bgcolor="white" p={2} borderRadius={2} sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)', width: '100%' }}>
                        <div>
                            <Box mt={5}>
                                <InputLabel htmlFor="editor-content" sx={{ mb: 1 }}>
                                    タイトル
                                </InputLabel>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    sx={{ my: 1 }}
                                />
                            </Box>

                            <Box mt={5}>
                                <InputLabel htmlFor="editor-content" sx={{ mb: 1 }}>
                                    本文
                                </InputLabel>
                                <SunEditor
                                    setOptions={{
                                        height: 200,
                                        buttonList: customButtonList,
                                    }}
                                    onChange={handleEditorChange}
                                />
                            </Box>
                            <input
                                ref={fileInputRef}
                                type="file"
                                multiple
                                onChange={handleFileSelect}
                                accept=".pdf,.xls,.xlsx,.gif,.jpeg,.jpg,.png,.svg,.dwf,.dwg,.dxf,.doc,.docx,.ppt,.pptx,.txt"
                                style={{ display: 'none' }}
                            />
                            <button
                                onClick={handleFileInputClick}
                                style={{ marginTop: '10px' }}
                            >
                                ファイルを選択
                            </button>
                            <div>{renderFileList()}</div>
                            <Box mt={5}>
                                <InputLabel htmlFor="editor-content" sx={{ mb: 1 }}>
                                    タグ
                                </InputLabel>

                                {categories.map((mainCategory) => (
                                    <Accordion key={mainCategory.main_category}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{
                                                backgroundColor: mainCategory.main_category === '機械系' ? '#ffe9e2'
                                                    : mainCategory.main_category === '情報IT系' ? '#e6f8d7'
                                                        : '#ffffe7'
                                            }}
                                        >
                                            <Typography variant="subtitle1">{mainCategory.main_category}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {mainCategory.sub_categories.map((subCategory) => (
                                                <Accordion key={subCategory.sub_category}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        style={{
                                                            backgroundColor: '#f9f9f9'
                                                        }}

                                                    >
                                                        <Typography variant="subtitle1">{subCategory.sub_category}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <FormGroup>
                                                            {subCategory.tags.map((tag) => (
                                                                <FormControlLabel
                                                                    key={tag}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={selectedTags.includes(tag)}
                                                                            onChange={handleTagChange}
                                                                            value={tag}
                                                                        />
                                                                    }
                                                                    label={tag}
                                                                />
                                                            ))}
                                                        </FormGroup>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                                <div style={{ marginTop: '50px' }}>
                                    <Typography variant="h6">選択されたタグ:</Typography>
                                    <div>
                                        {selectedTags.map((tag, index) => (
                                            <Chip
                                                key={index}
                                                label={tag.trim()}
                                                onDelete={() => handleDelete(tag)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </Box>
                            <Box mt={5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAnonymous}
                                            onChange={(e) => setIsAnonymous(e.target.checked)}
                                            color="primary"
                                        />
                                    }
                                    label="匿名で投稿する"
                                />
                            </Box>
                        </div>
                        <Button
                            fullWidth
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                            sx={{
                                my: 4,
                                height: '50px'
                            }}
                            onClick={() => PostQuestion()}
                            disabled={loading || !isTextLongEnough}
                        >
                            <Typography style={{ fontWeight: 'bold' }}>
                                質問を投稿する
                            </Typography>
                        </Button>
                        {errorMessage && (
                            <Typography variant="body2" color="error">
                                {errorMessage}
                            </Typography>
                        )}
                        {
                            !isTextLongEnough && (
                                <Typography color="error" variant="body2" sx={{ my: 2 }}>
                                    本文を10文字以上入力してください。
                                </Typography>
                            )
                        }
                        {loading && <CircularProgress />}
                    </Box>
                </Grid>
            </Grid >
        </div >
    );
};
