import DoneOutlineSharpIcon from '@mui/icons-material/DoneOutlineSharp';
import PersonIcon from '@mui/icons-material/Person';
import { Button } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import 'draft-js/dist/Draft.css';
import { React, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Header from '../header/Header';
import MenuButtons from '../menubuttons/MenuButtons';

// 遷移してきたとき以外の画面更新でuserId, questionIdがnullにならないようにする
function ChildComponent({
    userId,
    questionId
}) {
    // 画面が再描画される度にユーザーIDと質問IDをセットする
    useEffect(() => {
        if (userId && questionId) {
            console.log(userId)
            console.log(questionId)
        }
    }, [userId, questionId]);
}

// 画面が再描画される度に回答を取得するようにする
function AnswerList({
    questionId,
    userId,
    setAnswerCommentEditorProps,
    handleEditing,
    answer,
    solution_answers,
    userData,
    fileName,
    imagesAnswer,
    filesAnswer,
    notificationCount,
    notifications,
    categories,
}) {
    const navigate = useNavigate();
    const [answerName, setAnswerNname] = useState([]);
    const [answerUserIcon, setAnswerUserIcon] = useState([]);
    const isUserAnonymous = answer.is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';

    const displayUserName = answer.is_anonymous
        ? isAdmin
            ? `${answerName || answer.user_id} (匿名投稿)`
            : '匿名ユーザー'
        : answerName || answer.user_id;
    // ファイルをダウンロードする関数
    const downloadFile = async () => {
        console.log('ダウンロードするファイル:', fileName);
        try {
            const response = await axios.post(
                `/backend/download`,
                {
                    fileName: fileName
                },
                {
                    withCredentials: true,
                    responseType: 'arraybuffer'
                }
            );
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', extractFileName(fileName));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('File download failed:', error);
        }
    };

    // 既存のfile_nameと新しい画像・ファイルリストを表示するロジック
    const renderFileLinks = () => {
        const links = [];

        // 古いfile_nameが存在する場合はリンクを追加
        if (fileName) {
            links.push(
                <div key="old-file">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(fileName);
                    }}>{extractFileName(fileName)}</a>
                </div>
            );
        }

        // 新しい画像ファイルのリンクを追加
        imagesAnswer.forEach((image, index) => {
            links.push(
                <div key={`image-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(image.file_name);
                    }}>{extractFileName(image.file_name)}</a>
                </div>
            );
        });

        // 新しいその他のファイルのリンクを追加
        filesAnswer.forEach((file, index) => {
            links.push(
                <div key={`file-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(file.file_name);
                    }}>{extractFileName(file.file_name)}</a>
                </div>
            );
        });

        return links;
    };

    // ファイル名を取得する関数
    function extractFileName(str) {
        const regex = /.*?_([^_]+\.\w+)$/;
        const match = str.match(regex);
        return match ? match[1] : str;
    }
    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }
    
            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }
    
            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }
    
            code {
                font-family: 'Courier New', Courier, monospace;
            }
    
            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        async function getusers() {
            console.log(answer.user_id)
            try {
                const res = await axios.post(
                    `/backend/getusers`,
                    {
                        user_id: answer.user_id,
                        withCredentials: true
                    }
                );
                setAnswerNname(res.data.name);
                setAnswerUserIcon(res.data.icon);
                console.log(res.data)
            } catch (error) {
                console.error('ユーザー情報の取得に失敗しました。', error);
            }
        }
        getusers();
    }, []);

    // ユーザー画面に移動する
    const moveUsers = () => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: answer.user_id
            }
        })
    };

    return (
        <div>
            <Grid key={answer.answer_id} container direction="column" spacing={2}>
                <Grid item>
                    <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Avatar onClick={moveUsers} style={{ cursor: isUserAnonymous ? 'default' : 'pointer' }} >
                                {isUserAnonymous ? (
                                    <PersonIcon />
                                ) : answerUserIcon !== "null" ? (
                                    <img src={answerUserIcon} style={{ maxWidth: '100%', height: 'auto' }} alt="user icon" />
                                ) : (
                                    <PersonIcon />
                                )}
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                {displayUserName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <div
                        key={answer.answer_id}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        {solution_answers.includes(answer.id) && (
                            <Grid item>
                                <Typography variant="body2" style={{ color: 'green', fontWeight: 'bold' }}>
                                    解決に役立った回答
                                </Typography>
                            </Grid>
                        )}
                        <DisplayMarkup html={answer.html_text} />
                    </div>
                    {/* 添付ファイルがある場合のみリンクを表示 */}
                    {<div>
                        {renderFileLinks()}
                    </div>}
                </Grid>
                <Grid item>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Grid item>
                            {solution_answers.includes(answer.id) && (
                                <DoneOutlineSharpIcon fontSize="large" style={{ color: 'green' }} />
                            )}
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

// 画面が再描画される度に回答を取得するようにする
function CommentList({
    questionId,
    answerId,
    userId,
    setAnswerCommentEditorProps,
    handleEditing,
    comment,
    solution_comments,
    fileName,
    imagesComment,
    filesComment,
    notificationCount,
    notifications,
    categories,
    userName,
    userData,
}) {
    const navigate = useNavigate();
    const [commentName, setCommentName] = useState([]);
    const [commentUserIcon, setCommentUserIcon] = useState([]);
    const isUserAnonymous = comment.is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';

    const displayUserName = comment.is_anonymous
        ? isAdmin
            ? `${commentName || comment.user_id} (匿名投稿)`
            : '匿名ユーザー'
        : commentName || comment.user_id;

    console.log(comment)
    console.log(solution_comments)

    // ファイルをダウンロードする関数
    const downloadFile = async () => {
        console.log('ダウンロードするファイル:', fileName);
        try {
            const response = await axios.post(
                `/backend/download`,
                {
                    fileName: fileName
                },
                {
                    withCredentials: true,
                    responseType: 'arraybuffer'
                }
            );
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', extractFileName(fileName));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('File download failed:', error);
        }
    };

    const renderFileLinks = () => {
        const links = [];
        // 古いfile_nameが存在する場合はリンクを追加
        if (fileName) {
            links.push(
                <div key="old-file">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(fileName);
                    }}>{extractFileName(fileName)}</a>
                </div>
            );
        }

        // 新しい画像ファイルのリンクを追加
        imagesComment.forEach((image, index) => {
            links.push(
                <div key={`image-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(image.file_name);
                    }}>{extractFileName(image.file_name)}</a>
                </div>
            );
        });

        // 新しいその他のファイルのリンクを追加
        filesComment.forEach((file, index) => {
            links.push(
                <div key={`file-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(file.file_name);
                    }}>{extractFileName(file.file_name)}</a>
                </div>
            );
        });

        return links;
    };

    // ファイル名を取得する関数
    function extractFileName(str) {
        const regex = /.*?_([^_]+\.\w+)$/;
        const match = str.match(regex);
        return match ? match[1] : str;
    }

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        async function getusers() {
            console.log(comment.user_id)
            try {
                const res = await axios.post(
                    `/backend/getusers`,
                    {
                        user_id: comment.user_id,
                        withCredentials: true
                    }
                );
                setCommentName(res.data.name);
                setCommentUserIcon(res.data.icon);
                console.log(res.data)
            } catch (error) {
                console.error('ユーザー情報の取得に失敗しました。', error);
            }
        }
        getusers();
    }, []);

    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }
    
            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }
    
            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }
    
            code {
                font-family: 'Courier New', Courier, monospace;
            }
    
            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };

    // ユーザー画面に移動する
    const moveUsers = () => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: comment.user_id
            }
        })
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item container alignItems="center" spacing={2}>
                    <Grid item>
                        <Avatar onClick={moveUsers} style={{ cursor: isUserAnonymous ? 'default' : 'pointer' }} >
                            {isUserAnonymous ? (
                                <PersonIcon />
                            ) : commentUserIcon !== "null" ? (
                                <img src={commentUserIcon} style={{ maxWidth: '100%', height: 'auto' }} alt="user icon" />
                            ) : (
                                <PersonIcon />
                            )}
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" marginRight="10px">
                            {displayUserName}
                        </Typography>
                    </Grid>
                </Grid>
                {solution_comments.includes(comment.id) && (
                    <Grid item>
                        <Typography variant="body2" style={{ color: 'green', fontWeight: 'bold' }}>
                            解決に役立ったコメント
                        </Typography>
                    </Grid>
                )}
                <Grid item container xs={12} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <div key={comment.id}>
                            <DisplayMarkup html={comment.html_text} />
                        </div>
                        {/* 添付ファイルがある場合のみリンクを表示 */}
                        {<div>
                            {renderFileLinks()}
                        </div>}
                    </Grid>
                    <Grid item>
                        {solution_comments.includes(comment.id) && (
                            <DoneOutlineSharpIcon fontSize="large" style={{ color: 'green' }} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>

    );
};

function Commentditor(props) {
    // エディタスタイル
    const [editorContent, setEditorContent] = useState('');
    const [plainTextContent, setPlainTextContent] = useState('');
    const [isTextLongEnough, setIsTextLongEnough] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const customButtonList = [
        ['undo', 'redo'],
        ['fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image'],
    ];


    const handleImageUpload = async (files, info, uploadHandler) => {
        const file = files[0];
        console.log('画像をアップロード:', file);

        // FileReaderを使用して一時的なURLを生成
        const reader = new FileReader();
        reader.onloadend = () => {
            const tempUrl = reader.result;

            // 画像を選択した際に、その画像と一時的なURLをステートに保存
            setSelectedImages(prevImages => [...prevImages, { file, url: tempUrl }]);

            uploadHandler({ result: [tempUrl] });
        };
        reader.readAsDataURL(file);
    };

    // HTMLからプレーンテキストを抽出する関数
    function extractTextFromHtml(html) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    // エディタの内容を処理する関数
    const handleEditorChange = (content) => {
        setEditorContent(content);

        // エディタ内の画像URLを抽出
        const editorImageUrls = content.match(/<img [^>]*src="([^"]+)"[^>]*>/g)?.map(imgTag => {
            const match = imgTag.match(/src="([^"]+)"/);
            return match ? match[1] : '';
        }) || [];

        console.log('エディタ内の画像URL:', editorImageUrls);

        // selectedImagesからエディタ内にない画像を削除
        setSelectedImages(prevImages => prevImages.filter(img => editorImageUrls.includes(img.url)));

        // HTMLからプレーンテキストを抽出
        const plainText = extractTextFromHtml(content);
        setPlainTextContent(plainText);

        // プレーンテキストの長さを確認し、ステートを更新
        setIsTextLongEnough(plainText.length >= 10);
    };

    return (
        <div>
            <SunEditor
                onImageUploadBefore={handleImageUpload}
                setOptions={{
                    height: 200,
                    buttonList: customButtonList,
                }}
                onChange={handleEditorChange}
            />
            <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                sx={{
                    my: 2,
                }}
                onClick={() => props.Postfunction(props.answer_id, props.replied_comment_id, editorContent)}
            >
                送信する
            </Button>
        </div>
    );
}


export default function Resolved() {
    const navigate = useNavigate();
    const location = useLocation()
    const [question, setQuestion] = useState();
    const [solution, setSolution] = useState();
    const [solution_answers, setSolutionAnswers] = useState([]);
    const [solution_comments, setSolutionComments] = useState([]);
    const { questionId, questionUserId, notificationCount, notifications, userData, userId, category } = location.state || {};
    const [title, setTitle] = useState('')
    const [answerCommentEditorProps, setAnswerCommentEditorProps] = useState({});
    const [editing, setEditing] = useState(true)
    const [answers, setAnswers] = useState([]);
    const [answerId, setAnswerId] = useState([]);
    const [comments, setComments] = useState([]);
    const [replycomments, setReplyComments] = useState([]);
    const [repliedCommentId, setRepliedCommentId] = useState([]);
    const [editingAnswerId, setEditingAnswerId] = useState(null);
    const [editingAnswer, setEditingAnswer] = useState({ id: null, isEditing: false });
    const [showCommentsForAnswer, setShowCommentsForAnswer] = useState({});
    const [cookies, setCookie, removeCookie] = useCookies();
    const [newToken, setNewToken] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [tags, setTags] = useState([]);
    const [questionUserIcon, setQuestionUserIcon] = useState([]);
    const [questioName, setQuestioNname] = useState([]);
    const [fileName, setFileName] = useState('');
    const [fileNameAnswer, setFileNameAnswer] = useState('');
    const [fileNameComment, setFileNameComment] = useState('');
    const [imagesQuestion, setImagesQuestion] = useState([]);
    const [filesQuestion, setFilesQuestion] = useState([]);
    const [imagesAnswer, setImagesAnswer] = useState([]);
    const [filesAnswer, setFilesAnswer] = useState([]);
    const [imagesComment, setImagesComment] = useState([]);
    const [filesComment, setFilesComment] = useState([]);
    const isUserAnonymous = question && question.is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';
    const displayUserName = question && question.is_anonymous
    ? isAdmin
        ? `${questioName || questionUserId} (匿名投稿)`
        : '匿名ユーザー'
    : questioName || questionUserId;

    // ファイルをダウンロードする関数
    const downloadFile = async () => {
        console.log('ダウンロードするファイル:', fileName);
        try {
            const response = await axios.post(
                `/backend/download`,
                {
                    fileName: fileName
                },
                {
                    withCredentials: true,
                    responseType: 'arraybuffer'
                }
            );
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', extractFileName(fileName));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('File download failed:', error);
        }
    };

    // 既存のfile_nameと新しい画像・ファイルリストを表示するロジック
    const renderFileLinks = () => {
        const links = [];

        // 古いfile_nameが存在する場合はリンクを追加
        if (fileName) {
            links.push(
                <div key="old-file">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(fileName);
                    }}>{extractFileName(fileName)}</a>
                </div>
            );
        }

        // 新しい画像ファイルのリンクを追加
        imagesQuestion.forEach((image, index) => {
            links.push(
                <div key={`image-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(image.file_name);
                    }}>{extractFileName(image.file_name)}</a>
                </div>
            );
        });

        // 新しいその他のファイルのリンクを追加
        filesQuestion.forEach((file, index) => {
            links.push(
                <div key={`file-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(file.file_name);
                    }}>{extractFileName(file.file_name)}</a>
                </div>
            );
        });
        return links;
    };

    // ファイル名を取得する関数
    function extractFileName(str) {
        const regex = /.*?_([^_]+\.\w+)$/;
        const match = str.match(regex);
        return match ? match[1] : str;
    }

    // コメントの編集を切り替える関数
    const handleEditingComments = (answerId) => {
        if (editingAnswer.id === answerId) {
            setEditingAnswer({ id: answerId, isEditing: !editingAnswer.isEditing });
        } else {
            setEditingAnswer({ id: answerId, isEditing: true });
        }
    };

    // スタイルペーパの設定などの関数
    const StyledPaper = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        color: theme.palette.text.primary,
    }));

    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }
    
            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }
    
            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }
    
            code {
                font-family: 'Courier New', Courier, monospace;
            }
    
            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        console.log('ユーザー情報を取得します')
        async function getusers() {
            const res = await axios.post(
                `/backend/getusers`,
                {
                    user_id: questionUserId,
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestioNname(res.data.name);
            setQuestionUserIcon(res.data.icon);
        }
        if (questionUserId) {
            getusers();
        }
    }, [questionUserId, isUserAnonymous]);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log('質問を取得します');
        // questionIdを使ってバックエンドから回答を複数取得
        async function fetchQuestion() {
            const res = await axios.post(
                `/backend/getquestion`,
                {
                    question_id: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestion(res.data);
            setFileName(res.data.file_name);
            setImagesQuestion(res.data.images);
            setFilesQuestion(res.data.files);
            setTitle(res.data.title);
        }
        if (questionId) {
            fetchQuestion();
        }
    }, [userId, questionId]);

    // 画面が再描画される度に回答を取得するようにする
    useEffect(() => {
        console.log('回答を取得します');
        async function fetchAnswers() {
            const res = await axios.post(
                `/backend/getanswer`,
                {
                    question_id: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setAnswers(res.data);
            setAnswerId(res.data.map(answer => answer.id));
            setFileNameAnswer(res.data.map(answer => answer.file_name));
            setImagesAnswer(res.data.map(answer => answer.images).flat());
            setFilesAnswer(res.data.map(answer => answer.files).flat());
        }
        if (questionId) {
            fetchAnswers();
        }
    }, [questionId]);

    // 画面が再描画される度にコメントを取得するようにする
    useEffect(() => {
        async function fetchComments() {
            console.log('コメントを取得します');
            console.log(answerId)
            try {
                const promises = answerId.map(async (id) => {
                    const res = await axios.post(`/backend/getcomment`, {
                        answer_id: id,
                        withCredentials: true
                    });
                    console.log(res);
                    return res.data;
                });

                // すべてのPromiseが完了するまで待機し、結果をまとめる
                const results = await Promise.all(promises);
                const flattenedResults = results.flat();  // 結果をフラットにする
                console.log(flattenedResults);
                setComments(prevComments => [...prevComments, ...flattenedResults]);

                // imagesとfilesを抽出し、状態を更新
                const allImages = flattenedResults.map(comment => comment.images).flat();
                const allFiles = flattenedResults.map(comment => comment.files).flat();
                setImagesComment(allImages);
                setFilesComment(allFiles);

            } catch (error) {
                console.error("コメントの取得中にエラーが発生しました:", error);
            }
        }
        if (answerId.length) {
            fetchComments();
        }
    }, [answerId]);

    // 画面が再描画される度に返信コメントを取得するようにする
    useEffect(() => {
        console.log('返信コメントを取得します');
        async function fetchReplyComments() {
            const promises = repliedCommentId.map(async (id) => {
                const res = await axios.post(`/backend/getreplycomment`, {
                    replied_comment_id: id,
                    withCredentials: true
                });
                console.log(res)
                return res.data
            });

            // すべてのPromiseが完了するまで待機し、結果をまとめる
            const results = await Promise.all(promises);
            setReplyComments([...replycomments, ...results]);
            console.log(replycomments)
            if (replycomments == []) {
                setReplyComments([...replycomments, ...[0, 0, 0]]);
            }
        };
        if (repliedCommentId) {
            fetchReplyComments();
        }
    }, [repliedCommentId]);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log('解決方法を取得します');
        // questionIdを使ってバックエンドから回答を複数取得
        async function fetchSolution() {
            const res = await axios.post(
                `/backend/getsolution`,
                {
                    question_id: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setSolution(res.data.self_solution_text);
            setSolutionAnswers(res.data.answer_id);
            setSolutionComments(res.data.comment_id);
        }
        if (questionId) {
            fetchSolution();
        }
    }, [userId, questionId]);

    // 画面が再描画される度にタグを取得するようにする
    useEffect(() => {
        console.log('タグを取得します');
        async function getTags() {
            const res = await axios.post(
                `/backend/gettags`,
                {
                    questionId: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setTags(res.data);
        }
        getTags();
    }, [answerId]);

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '4px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={category}
                />
                <MenuButtons
                    navigate={navigate}
                    userId={userId}
                    buttonType={''}
                    notificationCount={notificationCount}
                    notifications={notifications}
                    userData={userData}
                    categories={category}
                />
            </Box>

            <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{ flexGrow: 1, backgroundColor: '#F2F2F2', minHeight: "100vh" }}>
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    <Box bgcolor="#F2F2F2" p={2} borderRadius={2} width="70%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <div>
                            <ChildComponent userId={userId} questionId={questionId} />
                        </div>
                        <Grid item xs={12} style={{ width: '100%' }}>
                        {question && (
                            <Box bgcolor="white" p={2} borderRadius={2} my={2} mb={4}>
                                <h1>質問</h1>
                                <StyledPaper
                                    sx={{
                                        my: 1,
                                        mx: 1,
                                        p: 2,
                                    }}
                                >
                                    {/* 質問者の情報 */}
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Avatar>
                                                {isUserAnonymous ? (
                                                    <PersonIcon />
                                                ) : questionUserIcon !== "null" ? (
                                                    <img src={questionUserIcon} style={{ width: '100%', height: 'auto' }} alt="user icon" />
                                                ) : (
                                                    <PersonIcon />
                                                )}
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                {displayUserName}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {/* 質問のタイトルと内容 */}
                                    <Grid container spacing={3} style={{ marginTop: '20px' }}>
                                        <Grid item xs>
                                            <Typography variant="h5" fontWeight="bold">{question.title}</Typography>
                                            <DisplayMarkup html={question.html_question} />
                                            {tags && tags.map((tag, index) => (
                                                <Chip key={index} label={tag} style={{ marginLeft: '5px', marginTop: '15px', marginBottom: '15px' }} />
                                            ))}
                                            {/* 添付ファイルがある場合のみリンクを表示 */}
                                            {renderFileLinks()}
                                        </Grid>
                                    </Grid>
                                </StyledPaper>
                            </Box>
                        )}
                            <Box bgcolor="white" p={2} borderRadius={2} my={2} mb={4}>
                                <h1>回答</h1>
                                <Box
                                    sx={
                                        {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            //border: 1,
                                            borderColor: 'secondary.main',
                                            my: 3,
                                            flexDirection: 'column'
                                        }}>
                                    <div>
                                        {answers.map((answer, index) => (
                                            <StyledPaper
                                                sx={{
                                                    my: 1,
                                                    mx: 'auto',
                                                    p: 2,
                                                }}
                                            >
                                                <AnswerList
                                                    questionId={questionId}
                                                    userId={userId}
                                                    setAnswerCommentEditorProps={setAnswerCommentEditorProps}
                                                    handleEditing={handleEditingComments}
                                                    answer={answer}
                                                    solution_answers={solution_answers}
                                                    userData={userData}
                                                    fileName={fileNameAnswer[index]}
                                                    downloadFile={downloadFile}
                                                    extractFileName={extractFileName}
                                                    imagesAnswer={imagesAnswer}
                                                    filesAnswer={filesAnswer}
                                                    notificationCount={notificationCount}
                                                    notifications={notifications}
                                                    categories={category}
                                                />
                                                <Button
                                                    onClick={() => {
                                                        setShowCommentsForAnswer((prevState) => ({
                                                            ...prevState,
                                                            [answer.id]: !prevState[answer.id],
                                                        }));
                                                    }}
                                                >
                                                    {`コメント ${comments
                                                        .flat()
                                                        .filter((comment) => comment.answer_id === answer.id).length
                                                        } 件`}
                                                </Button>
                                                <Collapse in={showCommentsForAnswer[answer.id] || false}>
                                                    {comments.filter((comment) => comment.answer_id === answer.id)
                                                        .map((filteredComment, index) => (
                                                            <StyledPaper
                                                                key={index}
                                                                sx={{
                                                                    my: 1,
                                                                    mx: 'auto',
                                                                    p: 2,
                                                                }}
                                                            >
                                                                <CommentList
                                                                    questionId={questionId}
                                                                    userId={userId}
                                                                    handleEditing={handleEditingComments}
                                                                    comment={filteredComment}
                                                                    userData={userData}
                                                                    solution_comments={solution_comments}
                                                                    fileName={fileNameComment[index]}
                                                                    downloadFile={downloadFile}
                                                                    extractFileName={extractFileName}
                                                                    imagesComment={imagesComment}
                                                                    filesComment={filesComment}
                                                                    notificationCount={notificationCount}
                                                                    notifications={notifications}
                                                                    categories={category}
                                                                />
                                                            </StyledPaper>
                                                        ))
                                                    }
                                                </Collapse>
                                                {editingAnswer.id === answer.id && editingAnswer.isEditing && (
                                                    <Commentditor {...answerCommentEditorProps} />
                                                )}
                                            </StyledPaper>
                                        ))}
                                    </div>
                                </Box>
                            </Box>
                            {solution !== "" && (
                                <>
                                    <Box bgcolor="white" p={2} borderRadius={2} my={2} mb={4}>
                                        <h1>解決方法</h1>
                                        <Box
                                            sx={
                                                {
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    borderColor: 'secondary.main',
                                                    my: 3,
                                                    flexDirection: 'column'
                                                }}>
                                            <StyledPaper
                                                sx={{
                                                    my: 1,
                                                    mx: 1,
                                                    p: 2,
                                                }}
                                            >
                                                <Grid container alignItems="center" spacing={3}>
                                                    <Grid item>
                                                        <Avatar>
                                                            {isUserAnonymous ? (
                                                                <PersonIcon />
                                                            ) : questionUserIcon !== "null" ? (
                                                                <img src={questionUserIcon} style={{ width: '100%', height: 'auto' }} alt="user icon" />
                                                            ) : (
                                                                <PersonIcon />
                                                            )}
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <Typography variant="h6">
                                                            {displayUserName}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={3} style={{ marginTop: '20px' }}>
                                                    <Grid item xs={12}>
                                                        <DisplayMarkup html={solution} />
                                                    </Grid>
                                                </Grid>
                                            </StyledPaper>
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </Grid>
                    </Box>
                </Grid>
            </Grid >
        </div >
    );
};
