import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import CustomLogger from '../logger/Logger';


const ChatWindow = ({ onClose, userId }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([{ isUser: false, text: "以下から選択してください" }]);
  const [choices, setChoices] = useState(["チャットで聞きたい", "マニュアルをもとに聞きたい", "蓄積されたQAをもとに聞きたい"]);
  const [additionalChoices, setAdditionalChoices] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [historyCount, setHistoryCount] = useState(0);
  const [model, setModel] = useState("gpt-4o-mini");
  const [temperature, setTemperature] = useState(1.0);
  const [hasHistory, setHasHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const logger = new CustomLogger();
  const messagesEndRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");


  // 設定ボタンをクリックしたときの処理
  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };
  const handleHistoryCountChange = (event, newValue) => {
    setHistoryCount(newValue);
  };
  const handleTemperatureChange = (event, newValue) => {
    setTemperature(newValue);
  };
  const handleButtonClick = () => {
    setAdditionalChoices([]);

    if (onClose) {
      onClose();
    }
  };

  // メッセージを送信したときの処理
  const handleSendMessage = async () => {
    // ローディング開始
    setIsLoading(true);

    // 質問IDを初期化
    let questionId = null;

    // メッセージが空の場合は何もしない
    if (message.trim() !== '') {
      // メッセージリストに追加
      const updatedMessages = [...messages, { isUser: true, text: message }];
      setMessages(updatedMessages);
      setHasHistory(true);

      try {
        // メッセージが選択肢でないときだけ、質問IDを更新
        if (messages[1] && messages[1].text === '通常のChatGPTに質問する' && messages[1].text === '社内文書用ChatGPTに質問する') {
          console.log('メッセージをサーバーに送信して保存:', message);
          const res = await axios.post(`/backend/save_chat_question`,
            {
              userId: userId,
              text: message,
              withCredentials: true
            }
          );
          questionId = res.data.id;
        }

        // 新たなリクエストを作成して、設定やメッセージをバックエンドに送る
        let chatRes;

        // 最新の特定のメッセージを探す
        const specialMessages = ["通常のChatGPTに質問する", "社内文書用ChatGPTに質問する"];
        let latestMessage = messages.slice().reverse().find(msg => specialMessages.includes(msg.text));

        // メッセージが見つからない場合、デフォルトの処理を行う
        if (!latestMessage) {
          latestMessage = { text: '' };
        } else {
          latestMessage = latestMessage.text;
        }

        //ChatGPTへ質問を送信
        if (latestMessage === '通常のChatGPTに質問する') {
          console.log('通常のChatGPTに質問します');

          // メッセージリストから特定のメッセージと最新のメッセージを除外
          const filteredMessages = updatedMessages.filter((msg, index) => {
            return msg.text !== "以下から選択してください" &&
              msg.text !== "通常のChatGPTに質問する" &&
              index !== updatedMessages.length - 1;
          });
          console.log(filteredMessages.slice(-15));

          // 通常のChatGPTへ質問を送信
          chatRes = await axios.post(`/backend/send_chat_question`,
            {
              userId: userId,
              questionId: questionId,
              settings: { model, historyCount, temperature },
              text: message,
              messages: filteredMessages.slice(-15),
              withCredentials: true
            }
          );
        } else if (latestMessage === '社内文書用ChatGPTに質問する') {
          console.log('社内文書用ChatGPTに質問します');

          // メッセージリストから特定のメッセージと最新のメッセージを除外
          const filteredMessages = updatedMessages.filter((msg, index) => {
            return msg.text !== "以下から選択してください" &&
              msg.text !== "社内文書用ChatGPTに質問します" &&
              index !== updatedMessages.length - 1;
          });
          console.log(filteredMessages.slice(-15));

          // 社内文書用ChatGPTへ質問を送信
          chatRes = await axios.post(`/backend/send_chat_question_internal_documents`,
            {
              userId: userId,
              questionId: questionId,
              settings: { model, historyCount, temperature },
              text: message,
              messages: filteredMessages.slice(-15),
              withCredentials: true
            }
          );
        }

        // レスポンスをチェックして、メッセージリストとCookieに追加
        if (chatRes.status === 200) {
          // レスポンスからメッセージを取得
          const newMessage = { isUser: false, text: chatRes.data };

          // メッセージリストに追加
          setMessages(prevMessages => [...prevMessages, newMessage]);

          // 最新の15メッセージのみを保持する
          const messagesToStore = [...updatedMessages, newMessage].slice(-15);

          // メッセージをCookieに保存
          document.cookie = `messages=${JSON.stringify(messagesToStore)}; path=/`;
        } else {
          setErrorMessage('エラーが発生しました。もう一度質問してください。');
          console.error('メッセージの受信中にエラーが発生しました:', chatRes.status);
        }
      } catch (error) {
        if (error.response && error.response.status === 504) {
          setErrorMessage("サーバーからの応答がタイムアウトしました。しばらくしてから再試行してください。");
        } else {
          setErrorMessage("メッセージの送信中に未知のエラーが発生しました。再移行してください。");
        }
        logger.error('メッセージの送信中にエラーが発生しました:', error);
      }
      // 入力フィールドをクリア
      setMessage('');
      setIsLoading(false); // ローディング終了
    }
  };

  // DBに初期設定を挿入
  const insertDefaultSettings = async () => {
    try {
      console.log('chatGPTの初期設定をDBに挿入します');
      const res = await axios.post(`/backend/save_user_settings`,
        {
          userId: userId,
          model: model,
          historyCount: historyCount,
          temperature: temperature,
          withCredentials: true
        }
      );
      console.log(res);
    } catch (error) {
      console.error('初期設定の挿入中にエラーが発生しました:', error);
    }
  }


  // ファイルをダウンロード
  const handleFileDownload = async (filename) => {
    console.log(`Attempting to download file: ${filename}`);
    try {
      const res = await axios.post('/backend/download_pdf_file', {
        uniqueFileName: filename
      }, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Download error:', error);
      alert('ファイルのダウンロードに失敗しました。');
    }
  };

  // コンテンツを描画
  const renderMessageContent = (text) => {
    const sourcePrefix = "ソース:";
    const pagePrefix = "ページ:";
    let formattedText = text;
    let lastIndex = 0;
    const elements = [];

    // 複数の参照を処理するためのループ
    while (formattedText.includes(sourcePrefix, lastIndex) && formattedText.includes(pagePrefix, lastIndex)) {
      const sourceIndex = formattedText.indexOf(sourcePrefix, lastIndex) + sourcePrefix.length;
      const pageIndex = formattedText.indexOf(pagePrefix, lastIndex);
      const filename = formattedText.substring(sourceIndex, pageIndex).trim();

      // ソースまでのテキストを追加
      elements.push(formattedText.substring(lastIndex, formattedText.indexOf(sourcePrefix, lastIndex)));

      // リンク部分を追加
      if (/^[\w,\s-]+\.[A-Za-z]{3}$/.test(filename)) {
        // ファイル名が `test_stres.pdf` のような形式の場合はリンクとして扱う
        elements.push(
          <a href="#" onClick={(e) => {
            e.preventDefault();
            handleFileDownload(filename);
          }} style={{ color: 'blue', textDecoration: 'underline' }}>
            {filename}
          </a>
        );
      } else {
        // それ以外の形式の場合は普通のテキストとして扱う
        elements.push(
          <span>
            {filename}
          </span>
        );
      }

      // ページ情報を次の行に表示
      elements.push(
        <br />,
        formattedText.substring(pageIndex, formattedText.indexOf('\n', pageIndex) + 1)
      );
      lastIndex = formattedText.indexOf('\n', pageIndex) + 1;  // 次の行から再開
    }

    // 最後のテキスト部分を追加
    elements.push(formattedText.substring(lastIndex));

    return <>{elements}</>;
  };


  // バックエンドから設定データを取得
  const fetchSettings = async () => {
    try {
      console.log('chatGPTの設定を取得します');
      const res = await axios.post(`/backend/get_user_settings`,
        {
          userId: userId,
          withCredentials: true
        });

      const { model, historyCount, temperature } = res.data;

      if (res.status === 200 && model && historyCount && temperature) {
        // 設定が存在すれば状態に保存
        setModel(model);
        setHistoryCount(historyCount);
        setTemperature(temperature);
      } else {
        // 設定が存在しなければ初期値をDBに挿入
        insertDefaultSettings();
      }
    } catch (error) {
      console.error('設定の取得中にエラーが発生しました:', error);
      // エラーが発生した場合でも初期設定を挿入
      insertDefaultSettings();
    }
  }

  // chatgptの設定を保存する
  function handleSaveSettings() {
    console.log('chatGPTの設定を保存します');
    axios.post(`/backend/save_user_settings`,
      {
        userId: userId,
        model: model,
        historyCount: historyCount,
        temperature: temperature,
        withCredentials: true
      }
    )
      .then((response) => {
        console.log('設定を保存しました:', response);
      })
      .catch((error) => {
        logger.error('設定の保存中にエラーが発生しました:', error);
      });
  }

  // クッキーから設定を読み込む
  function getCookie(name) {
    // "name=value"形式の文字列の配列を取得
    const cookieArray = document.cookie.split('; ');
    // 指定した名前のクッキーを探す
    for (let cookieString of cookieArray) {
      const [cookieName, cookieValue] = cookieString.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }

  // 履歴を削除したときの処理
  function handleDeleteHistory() {
    // メッセージリストをクリア
    setMessages([]);
    // 履死を削除されたので、hasHistoryをfalseに設定
    setHasHistory(false);
    // Cookieからメッセージの履歴を削除
    document.cookie = "messages=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // ChatWindowを初期状態に戻す
    setMessages([{ isUser: false, text: "以下から選択してください" }]);
    setChoices([]);
    setAdditionalChoices([]);
    setChoices(["チャットで聞きたい", "マニュアルをもとに聞きたい", "蓄積されたQAをもとに聞きたい"]);
  };

  // 選択肢を選択したときの処理
  function handleChoice(choice) {
    setMessages([...messages, { isUser: true, text: choice }]);
    if (choice === "チャットで聞きたい") {
      // 選択肢を更新
      setAdditionalChoices(["通常のChatGPTに質問する", "社内文書用ChatGPTに質問する"]);
      setChoices([]);
    } else {
      // 選択肢をクリア
      setAdditionalChoices([]);
      setChoices(null);
    }
  }

  // 設定画面の設定を読み込む
  useEffect(() => {
    console.log('設定を読み込みます');
    fetchSettings();
  }, []);

  // メッセージの履歴を読み込む
  useEffect(() => {
    // 初期化時に実行
    // Cookieから取得
    const storedMessages = getCookie('messages');
    if (storedMessages) {
      // 取得したデータをパースしてstateにセット
      setMessages(JSON.parse(storedMessages));
      setHasHistory(true);
    }
  }, []);

  // メッセージが更新されたらスクロールを最下部に移動
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <Draggable handle=".chat-header">
      <ResizableBox width={700} height={600} >
        <Paper
          sx={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            bottom: '100px',
            right: '20px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '15px',
          }}
        >
          <Box
            className="chat-header"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '8px',
              bgcolor: '#03a9f4',
              px: 0,
              py: 1,
            }}
          >
            <IconButton onClick={handleSettingsClick}>
              <SettingsIcon />
            </IconButton>
            <Drawer anchor="right" open={settingsOpen} onClose={handleSettingsClick}>
              <Box sx={{ width: 250, p: 2 }}>
                <h2>ChatGPT設定</h2>

                <Box mb={4}>
                  <h3>model: {model}</h3>
                  <FormControl fullWidth>
                    <Select
                      value={model}
                      onChange={(e) => setModel(e.target.value)}
                    >
                      <MenuItem value="gpt-3.5-turbo" disabled>gpt-3.5-turbo</MenuItem>
                      <MenuItem value="gpt-3.5-turbo-16k" disabled>gpt-3.5-turbo-16k</MenuItem>
                      <MenuItem value="gpt-4o-mini" >gpt-4.0-mini</MenuItem>
                      <MenuItem value="gpt-4" disabled>gpt-4</MenuItem>
                      <MenuItem value="gpt-4-32k" disabled>gpt-4-32k</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Box mb={4}>
                  <h3>history: {historyCount}</h3>
                  <Slider
                    value={historyCount}
                    min={0}
                    max={5}
                    step={1}
                    onChange={handleHistoryCountChange}
                    valueLabelDisplay="auto"
                  />
                </Box>

                <Box mb={2}>
                  <h3>Temperature: {temperature.toFixed(1)}</h3>
                  <Slider
                    value={temperature}
                    min={0}
                    max={2}
                    step={0.1}
                    onChange={handleTemperatureChange}
                    valueLabelDisplay="auto"
                  />
                </Box>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveSettings}
                >
                  設定を保存
                </Button>
              </Box>
            </Drawer>
            <h2 style={{ color: 'white', fontSize: '1.2em', marginLeft: '30px' }}>チャットで質問</h2>
            <Box>
              <IconButton onClick={handleDeleteHistory} disabled={!hasHistory}>
                <DeleteIcon style={{ color: hasHistory ? 'white' : 'gray' }} />
              </IconButton>

              <IconButton onClick={handleButtonClick}>
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              flex: 1,
              overflow: 'auto',
              position: 'relative',
            }}
          >
            {isLoading ? (
              <Box
                sx={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 9999,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '20px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '10px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                }}
              >
                <CircularProgress size={60} />
                <Typography variant="subtitle1" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
                  処理中です、しばらくお待ちください...
                </Typography>
              </Box>
            ) : null}
            <List>
              {messages.map((msg, index) => (
                <ListItem key={index} sx={{ justifyContent: msg.isUser ? 'flex-end' : 'flex-start', marginBottom: '8px' }}>
                  <Box sx={{
                    position: 'relative',
                    backgroundColor: msg.isUser ? '#d4edda' : '#d0d0d0',
                    borderRadius: '12px',
                    maxWidth: '50%',
                    p: 1,
                    '::after': msg.isUser ? {
                      content: '""',
                      position: 'absolute',
                      right: '-10px',
                      top: '10px',
                      borderWidth: '10px',
                      borderStyle: 'solid',
                      borderColor: '#d4edda transparent transparent transparent',
                    } : {
                      content: '""',
                      position: 'absolute',
                      left: '-10px',
                      top: '10px',
                      borderWidth: '10px',
                      borderStyle: 'solid',
                      borderColor: '#d0d0d0 transparent transparent transparent',
                    }
                  }}>
                    <div style={{ whiteSpace: 'pre-line' }}>
                      <ListItemText primary={renderMessageContent(msg.text)} />
                    </div>
                  </Box>
                </ListItem>
              ))}
              <div ref={messagesEndRef} />
            </List>
          </Box>

          {choices ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 'auto',
              }}
            >
              {choices.map((choice, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => handleChoice(choice)}
                  disabled={choice === "蓄積されたQAをもとに聞きたい" || choice === "マニュアルをもとに聞きたい"}
                  sx={{ m: 1, p: 0.5, textTransform: 'none', height: '60px' }}
                >
                  {choice}
                </Button>
              ))}
              {additionalChoices.map((choice, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => handleChoice(choice)}
                  sx={{ m: 1, p: 0.5, textTransform: 'none', height: '60px' }}
                >
                  {choice}
                </Button>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 'auto',
              }}
            >
              <TextField
                label="メッセージを入力してください"
                variant="outlined"
                value={message}
                fullWidth
                multiline
                rows={2}
                maxRows={5}
                onChange={(e) => setMessage(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleSendMessage}
                        sx={{ bgcolor: '#03a9f4' }}
                      >
                        <SendIcon style={{ color: 'white' }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
          {errorMessage && (
            <div style={{ color: "red", marginTop: "10px" }}>
              {errorMessage}
            </div>
          )}
        </Paper>
      </ResizableBox>
    </Draggable >
  );
};

export default ChatWindow;