import { DriveFileMove as MoveIcon } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UpdateIcon from '@mui/icons-material/Update';
import {
    Box, Button, Card, CardContent,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import MenuButtons from '../menubuttons/MenuButtons';
import HierarchyTreeView from './HierarchyTreeView';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';

// 階層追加ダイアログの定義
function AddHierarchyDialog({ open, onClose, onAdd, parentId }) {
    const [newHierarchyName, setNewHierarchyName] = useState("");
    const handleAddClick = () => {
        if (!newHierarchyName) {
            alert("階層名を入力してください。");
            return;
        }
        onAdd(parentId, newHierarchyName);
        setNewHierarchyName("");
        onClose();
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>新しい階層を追加</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    fullWidth
                    label="階層名"
                    value={newHierarchyName}
                    onChange={(e) => setNewHierarchyName(e.target.value)}
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={handleAddClick} color="primary">追加</Button>
            </DialogActions>
        </Dialog>
    );
}

// ConfirmDeleteDialogの定義
function ConfirmDeleteDialog({ open, onClose, onConfirm }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>本当に削除しますか？</DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={onConfirm} color="primary">削除</Button>
            </DialogActions>
        </Dialog>
    );
}

// 削除完了通知ダイアログの定義
function DeleteSuccessDialog({ open, onClose, fetchFileList }) {
    const handleClose = () => {
        fetchFileList();
        onClose();
    };
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>削除されました</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    );
}

// 更新成功通知ダイアログの定義
function UpdateSuccessDialog({ open, onClose, fetchFileList }) {
    const handleClose = () => {
        window.location.reload();
        fetchFileList();
        onClose();
    };
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>更新されました</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    );
}


// 移動成功通知ダイアログの定義
function MoveSuccessDialog({ open, onClose, fetchFileList }) {
    const handleClose = () => {
        fetchFileList();

        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>移動完了</DialogTitle>
            <DialogContent>
                <Typography>ファイルが正常に移動されました。</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    );
}
// 編集成功通知ダイアログの定義
function EditSuccessDialog({ open, onClose, fetchFileList }) {
    const handleClose = () => {
        fetchFileList();
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>編集完了</DialogTitle>
            <DialogContent>
                <Typography>ファイルが正常に編集されました。</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    );
}


// ファイル更新確認ダイアログの定義
function ConfirmUpdateDialog({ open, onClose, onConfirm }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>ファイルを更新しますか？</DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={onConfirm} color="primary">はい</Button>
            </DialogActions>
        </Dialog>
    );
}

// ファイル移動ダイアログの定義
function MoveFileDialog({ open, onClose, hierarchyData, onMove, fileList, checkedFiles }) {
    const [selectedHierarchyId, setSelectedHierarchyId] = useState("");

    const handleMove = () => {
        if (!selectedHierarchyId) {
            alert('階層を選択してください。');
            return;
        }
        // ここでcheckedFilesを用いて選択されたファイルのリストを作成
        const filesToMove = Array.from(checkedFiles).map(fileId =>
            fileList.find(f => f.file_name === fileId)
        );
        onMove(filesToMove, selectedHierarchyId);
        onClose();
    };
    // 階層選択オプションのレンダリング
    function renderHierarchyOptions(hierarchies, prefix = '') {
        let items = []; // 配列を初期化
        hierarchies.forEach(hierarchy => {
            items.push(
                <MenuItem key={hierarchy.id} value={hierarchy.id}>
                    {prefix + hierarchy.name}
                </MenuItem>
            );
            if (hierarchy.children) {
                items = items.concat(renderHierarchyOptions(hierarchy.children, prefix + "  "));
            }
        });
        return items;
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>ファイルを移動</DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                    <InputLabel>階層</InputLabel>
                    <Select
                        value={selectedHierarchyId}
                        onChange={(e) => setSelectedHierarchyId(e.target.value)}
                        displayEmpty
                    >
                        <MenuItem value="">
                            <em>選択してください</em>
                        </MenuItem>
                        {renderHierarchyOptions(hierarchyData)}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={handleMove} color="primary">移動</Button>
            </DialogActions>
        </Dialog>
    );
}

// ファイル名変更ダイアログの定義
function RenameFileDialog({ open, onClose, file, onRename }) {
    const initialName = file ? file.title.replace(/\.[^/.]+$/, "") : '';
    const [newFileName, setNewFileName] = useState(initialName);
    const extension = file ? file.title.split('.').pop() : '';
    const handleRename = () => {
        if (!newFileName.trim()) {
            alert('ファイル名を入力してください。');
            return;
        }
        const fullName = `${newFileName}.${extension}`;
        console.log('Selected file for rename:', file);
        console.log('Sending rename request with:', { fileId: file.file_name, newName: fullName });
        onRename({ fileId: file.file_name, newName: fullName });
        onClose();
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>ファイル名を変更</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="新しいファイル名"
                    type="text"
                    fullWidth
                    value={newFileName}
                    onChange={(e) => setNewFileName(e.target.value)}
                />
                <Typography variant="body2" color="textSecondary">
                    .{extension}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={handleRename} color="primary">変更</Button>
            </DialogActions>
        </Dialog>
    );
}

// 階層名変更ダイアログの定義
function ConfirmRetrieveChromaDialog({ open, onClose, onConfirm }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Chroma再取得の確認</DialogTitle>
            <DialogContent>
                <Typography>Chromaデータを再取得すると、現在のChromaに登録されているデータが削除され、新しいデータで上書きされます。</Typography>
                <Typography>この操作は元に戻すことができません。本当に実行しますか？</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>キャンセル</Button>
                <Button onClick={onConfirm} color="primary">はい</Button>
            </DialogActions>
        </Dialog>
    );
}

// Chroma再取得成功通知ダイアログの定義
function RetrieveChromaSuccessDialog({ open, onClose }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>再取得成功</DialogTitle>
            <DialogContent>
                <Typography>Chromaデータが正常に再取得されました。</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export default function Management() {
    const [hierarchyData, setHierarchyData] = useState([]);
    const [selectedHierarchy, setSelectedHierarchy] = useState(null);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, notificationCount, notifications, category } = location.state || {};
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [userData, setUserData] = useState({
        id: userId,
        password: '',
        icon: '',
        name: '',
        affiliation: '',
        skill: '',
        skill_level: ''
    });
    const [fileList, setFileList] = useState([]);
    const [hierarchyMap, setHierarchyMap] = useState({});
    const [moveDialogOpen, setMoveDialogOpen] = useState(false);
    const [fileToMove, setFileToMove] = useState(null);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [checkedFiles, setCheckedFiles] = useState(new Set());
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const handleCloseAddDialog = () => setOpenAddDialog(false);
    const [selectedHierarchyId, setSelectedHierarchyId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [newHierarchyName, setNewHierarchyName] = useState('');
    const [parentId, setParentId] = useState(null);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [deleteTargetId, setDeleteTargetId] = useState(null);
    const [currentNode, setCurrentNode] = useState(null);
    const [openRenameDialog, setOpenRenameDialog] = useState(false);
    const [renameValue, setRenameValue] = useState('');
    const [moveSuccessOpen, setMoveSuccessOpen] = useState(false);
    const [updateSuccessOpen, setUpdateSuccessOpen] = useState(false);
    const [editSuccessOpen, setEditSuccessOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [retrieveChromaDialogOpen, setRetrieveChromaDialogOpen] = useState(false);
    const [retrieveChromaSuccessOpen, setRetrieveChromaSuccessOpen] = useState(false);
    const isIndeterminate = checkedFiles.size > 0 && checkedFiles.size < fileList.length;
    const isAllSelected = fileList.length > 0 && checkedFiles.size === fileList.length;

    // 階層を選択または選択解除する関数
    const handleSelectHierarchy = (hierarchy) => {
        console.log('Selected hierarchy:', hierarchy);
        setSelectedHierarchyId(hierarchy.id);
        setSelectedHierarchy(hierarchy);
        if (hierarchy && hierarchy.name) {
            fetchFileList(hierarchy.name);
        } else {
            console.error('階層が正しく設定されていません。');
        }
    };

    // 階層オプションをレンダリングする関数
    const renderHierarchyOptions = (hierarchies, prefix = '') => {
        return hierarchies.map((hierarchy) => [
            <MenuItem key={hierarchy.id} value={hierarchy.id}>
                {prefix + hierarchy.name}
            </MenuItem>,
            hierarchy.children && renderHierarchyOptions(hierarchy.children, prefix + "--")
        ]);
    };

    // ファイル選択のトグル
    const toggleFileSelection = (fileId) => {
        const newCheckedFiles = new Set(checkedFiles);
        if (newCheckedFiles.has(fileId)) {
            newCheckedFiles.delete(fileId);
        } else {
            newCheckedFiles.add(fileId);
        }
        setCheckedFiles(newCheckedFiles);
    };

    // 全選択・全解除
    const toggleSelectAll = () => {
        if (checkedFiles.size === fileList.length) {
            setCheckedFiles(new Set());
        } else {
            setCheckedFiles(new Set(fileList.map(file => file.file_name)));
        }
    };

    // ファイル名変更ダイアログの表示
    const handleOpenRenameDialog = () => {
        if (checkedFiles.size === 1) {
            const fileId = Array.from(checkedFiles)[0];
            const file = fileList.find(f => f.file_name === fileId);
            setCurrentFile(file);
            setRenameDialogOpen(true);
        } else {
            alert('ファイルを一つ選択してください。');
        }
    };

    // ファイル名変更ダイアログの非表示
    const handleCloseRenameDialog = () => {
        setRenameDialogOpen(false);
    };

    // ファイル名変更処理
    const handleRenameFile = ({ fileId, newName }) => {
        axios.post('/backend/renameFile', { fileId, newName })
            .then(response => {
                // 成功時の処理
                setEditSuccessOpen(true);
                fetchFileList();
            })
            .catch(error => {
                // エラー時の処理
                console.error('Rename error:', error);
                alert(`エラーが発生しました: ${error.message}`);
            });
    };

    // 階層名変更ダイアログの表示
    const handleOpenRenameDialogForHierarchy = () => {
        if (selectedHierarchy) {
            setCurrentNode(selectedHierarchy);
            setRenameValue(selectedHierarchy.name);
            setOpenRenameDialog(true);
        } else {
            alert('階層を選択してください。');
        }
    };

    // 名称変更処理
    const handleRename = () => {
        console.log('階層名変更:', currentNode, renameValue);
        if (!currentNode || !renameValue.trim()) {
            alert('階層名を入力してください。');
            return;
        }

        // 階層の名称変更リクエストをサーバーに送信
        axios.post('/backend/renameHierarchy', {
            id: currentNode.id,
            newName: renameValue
        })
            .then(response => {
                alert('階層名が更新されました。');
                fetchHierarchyData();
                setOpenRenameDialog(false);
            })
            .catch(error => {
                console.error('階層名の更新に失敗しました:', error);
                alert('階層名の更新に失敗しました。詳細: ' + error.message);
            });
    };

    // 階層名変更ダイアログの表示
    const handleOpenDialog = () => {
        if (selectedHierarchy) {
            setParentId(selectedHierarchy.id);
        } else {
            setParentId(null);
        }
        setOpenDialog(true);
    };

    // 階層名変更ダイアログの非表示
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setNewHierarchyName('');
        setParentId(null);
    };

    // 階層名変更処理
    const handleAdd = () => {
        if (newHierarchyName.trim() === "") {
            alert("階層名を入力してください。");
            return;
        }
        handleAddHierarchy(parentId, newHierarchyName);
        handleCloseDialog();
    };

    // 階層削除ダイアログの表示
    const handleOpenConfirmDialog = (id) => {
        setDeleteTargetId(id);
        setOpenConfirmDialog(true);
    };

    // 階層削除ダイアログの非表示
    const handleDeleteHierarchy = async (id) => {
        try {
            // バックエンドの階層削除エンドポイントにDELETEリクエストを送信
            const response = await axios.delete(
                `/backend/deleteHierarchy/${id}`
            );
            console.log(response.data);
            alert('階層とその内容が削除されました。');
            // 更新
            window.location.reload();

        } catch (error) {
            console.error('階層の削除に失敗しました:', error);
            alert('階層の削除に失敗しました。');
        }
    };

    // ファイル削除時のハンドラ
    const handleRemoveFile = (fileName) => {
        // 選択されたファイルから指定したファイルを削除
        setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));

        // input 要素の状態をリセット
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    // 階層データの取得
    const fetchHierarchyData = async () => {
        // 階層データの取得
        try {
            const response = await axios.get('/backend/gethierarchy');
            setHierarchyData(response.data);
        } catch (error) {
            console.error('階層データの取得に失敗しました', error);
        }
    };

    // 階層に紐づくファイルリストの取得
    const fetchFileList = async (hierarchyName) => {
        if (!hierarchyName) {
            console.error('階層名が設定されていません。');
            return;
        }

        console.log('階層名:', hierarchyName);
        try {
            const response = await axios.get(`/backend/getfiles?hierarchyName=${encodeURIComponent(hierarchyName)}`);
            console.log('ファイルリスト:', response.data);
            setFileList(response.data);
        } catch (error) {
            console.error('ファイルリストの取得に失敗しました', error);
        }
    };

    // 階層の追加
    const handleAddHierarchy = async (parentId, name) => {
        try {
            await axios.post('/backend/addhierarchy', { parentId, name });
            // 階層データを再取得することでUIを更新
            fetchHierarchyData();
        } catch (error) {
            console.error('階層の追加に失敗しました', error);
        }
    };

    // ファイルアップロード処理
    const handleFileUpload = async () => {
        setLoading(true); // ローディング開始
        setLoadingMessage("pdfをデータベースに登録中...");

        // ファイルが選択されていない場合はアラートを表示して処理を中断
        if (!fileInputRef.current.files.length || !selectedHierarchy) {
            alert('ファイルが選択されていないか、階層が選択されていません。');
            setLoading(false); // ローディング終了
            setLoadingMessage("");
            return;
        }

        // ファイルをフォームデータに追加
        const formData = new FormData();
        Array.from(fileInputRef.current.files).forEach(file => {
            formData.append('files', file);
        });

        // 階層名をフォームデータに追加
        formData.append('hierarchyName', selectedHierarchy.name);
        formData.append('userId', userId);

        // ファイルアップロード
        try {
            await axios.post(
                '/backend/uploadpdf',
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });
            fetchFileList(selectedHierarchy.name);
            setSelectedFiles([]);
        } catch (error) {
            if (error.response && error.response.data.error) {
                alert(error.response.data.error);
            } else {
                console.error('ファイルのアップロードに失敗しました', error);
                alert('ファイルのアップロードに失敗しました。');
            }
        } finally {
            // ローディング終了
            setLoading(false);
            setLoadingMessage("");

            // input 要素の状態をリセット
            // input 要素の状態をリセット
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    // ファイルダウンロード処理
    const handleDownload = async (uniqueFileName, hierarchyName) => {
        console.log('ファイルダウンロード:', uniqueFileName, hierarchyName);
        try {
            const response = await axios.post('/backend/pdfdownload', {
                uniqueFileName,
                hierarchyName
            }, {
                responseType: 'blob',
            });
            console.log('ファイルダウンロードの応答:', response);
    
            // Content-Disposition ヘッダーからファイル名を取得
            const contentDisposition = response.headers['content-disposition'];
            // Content-Disposition ヘッダーが存在する場合はファイルをダウンロード
            if (contentDisposition) {
                console.log('Content-Disposition:', contentDisposition);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', extractFileName(contentDisposition));
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Content-Disposition header is missing');
                alert('ファイル名を取得できませんでした。');
            }
        } catch (error) {
            console.error('ファイルダウンロードエラー', error);
            alert('ファイルのダウンロードに失敗しました。');
        }
    };

    // ファイル名を抽出する関数
    const extractFileName = (contentDisposition) => {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            let filename = matches[1].replace(/['"]/g, '');
            return filename.charAt(0).toLowerCase() + filename.slice(1);
        }
        return 'downloaded_file';
    };
    // ファイルリストの取得
    const handleFileChange = (event) => {
        // 選択されたファイルのリストを取得
        const files = event.target.files;
        // ファイルリストをステートに設定
        setSelectedFiles(Array.from(files));
    };

    // 階層データの取得
    useEffect(() => {
        fetchHierarchyData();
    }, []);

    // ユーザーデータの取得
    useEffect(() => {
        const fetchHierarchyData = async () => {
            try {
                const response = await axios.get(
                    '/backend/gethierarchy'
                );
                const data = response.data;
                setHierarchyData(data);

                // 階層マップを構築
                const map = buildHierarchyMap(data);
                setHierarchyMap(map);
            } catch (error) {
                console.error('階層データの取得に失敗しました', error);
            }
        };
        fetchHierarchyData();
    }, []);

    // ファイルリストの取得
    useEffect(() => {
        // クリックイベントを処理する関数
        const handleClickOutside = (event) => {
            // 階層リストの要素を取得
            const element = document.getElementById('hierarchy-list');
            // クリックが階層リストの外で発生したか確認
            if (element && !element.contains(event.target)) {
                setSelectedHierarchyId(null);
            }
        };
        // イベントリスナーを追加
        document.addEventListener('mousedown', handleClickOutside);
        // クリーンアップ関数
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // 階層マップを構築する関数
    const buildHierarchyMap = (hierarchyList) => {
        let map = {};
        const buildMap = (list, parentId = null) => {
            list.forEach((item) => {
                map[item.id] = { ...item, parentId };
                if (item.children) {
                    buildMap(item.children, item.id);
                }
            });
        };
        buildMap(hierarchyList);
        return map;
    };

    // ファイル削除ボタンコンポーネント
    function FileDeleteButton({ fetchFileList }) {
        const [dialogOpen, setDialogOpen] = useState(false);
        const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);

        // ファイル削除処理
        const handleDelete = async () => {
            Promise.all(Array.from(checkedFiles).map(fileId =>
                axios.post(`/backend/deletefile`, { fileId })
            )).then(() => {
                setDeleteSuccessOpen(true);
                fetchFileList();
                setCheckedFiles(new Set());
            }).catch(error => {
                console.error('Delete error:', error);
                alert('ファイルの削除に失敗しました。');
            }).finally(() => {
                setDialogOpen(false);
            });
        };
        return (
            <>
                <Button color="error" startIcon={<DeleteIcon />} onClick={() => setDialogOpen(true)} disabled={checkedFiles.size === 0}>
                    削除
                </Button>
                <ConfirmDeleteDialog open={dialogOpen} onClose={() => setDialogOpen(false)} onConfirm={handleDelete} />
                <DeleteSuccessDialog open={deleteSuccessOpen} onClose={() => setDeleteSuccessOpen(false)} fetchFileList={fetchFileList} />
            </>
        );
    }

    // ファイル更新ボタンコンポーネント
    function FileUpdateButton({ selectedHierarchy, userId, fetchFileList, fileList, checkedFiles }) {
        const fileUpdateInputRef = useRef(null);
        const [confirmOpen, setConfirmOpen] = useState(false);

        // ファイル更新処理
        const handleFileUpdate = async () => {
            const files = fileUpdateInputRef.current.files;
            const file = files[0];
            const selectedFileId = Array.from(checkedFiles)[0];
            const selectedFile = fileList.find(f => f.file_name === selectedFileId);

            // ファイルが選択されていない場合はアラートを表示して処理を中断
            if (files.length === 0) {
                alert("ファイルが選択されていません。");
                return;
            }

            // 選択されたファイルが存在しない場合はアラートを表示して処理を中断
            if (!selectedFile || file.name !== selectedFile.title) {
                alert("同名のファイルが存在しません。更新するには、同名のファイルを選択してください。");
                return;
            }

            // ファイル更新用のフォームデータを準備
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileName', file.name);
            formData.append('hierarchyName', selectedHierarchy.name);

            // ファイル更新リクエストを送信
            try {
                const response = await axios.post(
                    '/backend/updatefile',
                    formData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                if (response.status === 200) {
                    setUpdateSuccessOpen(true);
                    fetchFileList(selectedHierarchy.name);
                    setCheckedFiles(new Set());
                }
            } catch (error) {
                console.error('Update error:', error);
                alert('ファイルの更新に失敗しました。');
            }
        };

        return (
            <>
                <Tooltip title="更新">
                    <span>
                        <Button
                            startIcon={<UpdateIcon />}
                            onClick={() => setConfirmOpen(true)}
                            color="primary"
                            disabled={checkedFiles.size !== 1}
                        >
                            更新
                        </Button>
                    </span>
                </Tooltip>
                <input
                    type="file"
                    ref={fileUpdateInputRef}
                    onChange={handleFileUpdate}
                    style={{ display: 'none' }}
                    accept=".pdf"
                />
                <ConfirmUpdateDialog
                    open={confirmOpen}
                    onClose={() => setConfirmOpen(false)}
                    onConfirm={() => {
                        setConfirmOpen(false);
                        fileUpdateInputRef.current.click();
                    }}
                />
            </>
        );
    }

    // ファイル移動ダイアログを開く
    const handleOpenMoveDialog = (file) => {
        console.log('Selected file for moving:', file);
        setFileToMove(file);
        setMoveDialogOpen(true);
    };

    // ファイル移動ダイアログを閉じる
    const handleCloseMoveDialog = () => {
        setMoveDialogOpen(false);
    };

    // ファイル移動処理
    const handleMoveFile = async (fileList, newHierarchyId) => {
        if (!fileList || !newHierarchyId) {
            alert("ファイルが選択されていないか、移動先の階層が指定されていません。");
            return;
        }
        try {
            const movePromises = fileList.map(file => {
                return axios.post('/backend/movefile', {
                    fileId: file.file_name,
                    newHierarchyId: newHierarchyId,
                });
            });

            const results = await Promise.allSettled(movePromises);
            const successCount = results.filter(result => result.status === 'fulfilled').length;

            if (successCount > 0) {
                setMoveSuccessOpen(true);
            } else {
                alert("ファイルの移動に失敗しました。詳細はコンソールを確認してください。");
            }

            fetchFileList(selectedHierarchy.name);

        } catch (error) {
            console.error('ファイルの移動に失敗しました:', error);
            alert('ファイルの移動に失敗しました。');
        }

        setMoveDialogOpen(false);
    };

    // Chroma再取得確認ダイアログを開く
    const handleOpenRetrieveChromaDialog = () => {
        setRetrieveChromaDialogOpen(true);
    };

    // Chroma再取得確認ダイアログを閉じる
    const handleCloseRetrieveChromaDialog = () => {
        setRetrieveChromaDialogOpen(false);
    };

    // Chroma再取得成功通知ダイアログを閉じる
    const handleCloseRetrieveChromaSuccessDialog = () => {
        setRetrieveChromaSuccessOpen(false);
    };

    // Chroma再取得処理
    const handleRetrieveChroma = async () => {
        try {
            const response = await axios.post('/backend/retrieve-chroma', { fileIds: Array.from(checkedFiles) });

            if (response.status === 200) {
                console.log('Chromaの再取得結果:', response.data);
                setRetrieveChromaSuccessOpen(true);  // 成功ダイアログを表示
            } else {
                console.error('Chromaの再取得に失敗しました:', response.data.error);
            }
        } catch (error) {
            console.error('Chromaの再取得に失敗しました:', error);
        } finally {
            handleCloseRetrieveChromaDialog();
        }
    };

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={category}
                />
                <MenuButtons
                    navigate={navigate}
                    userId={userId}
                    buttonType={''}
                    notificationCount={notificationCount}
                    notifications={notifications}
                    userData={userData}
                    categories={category}
                />
            </Box>
            <Grid container item xs={12} sm={12} md={12} justifyContent="center" alignItems="center"
                sx={{ flexGrow: 1, backgroundColor: '#F2F2F2', overflow: 'auto' }}>
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={3} justifyContent="center" alignItems="flex-start" sx={{ p: 2 }}>
                        {/* タイトル */}
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    bgcolor: 'primary.main',
                                    color: 'primary.contrastText',
                                    p: 2,
                                    borderRadius: 1,
                                    textAlign: 'center',
                                    mb: 3,
                                }}
                            >
                                <Typography variant="h4" component="h1" sx={{ textAlign: 'center', mb: 4 }}>
                                    PDFファイルの登録
                                </Typography>
                            </Box>
                        </Grid>

                        {/* 階層管理ツリー */}
                        <Grid item xs={12} md={4}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="h5" component="div">
                                            フォルダ管理
                                        </Typography>
                                        <Box>
                                            {/* 新しい階層を追加するためのボタン */}
                                            <IconButton
                                                onClick={() => handleOpenDialog()}
                                                color="primary"
                                                title="新しい階層を追加"
                                                sx={{ ml: 1 }}
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>

                                            {/* 選択した階層の名称を変更するためのボタン */}
                                            <IconButton
                                                onClick={() => handleOpenRenameDialogForHierarchy()}
                                                color="secondary"
                                                title="階層名を変更"
                                                sx={{ ml: 1 }}
                                                disabled={!selectedHierarchy}
                                            >
                                                <EditIcon />
                                            </IconButton>

                                            {/* 選択した階層を削除するためのボタン */}
                                            <IconButton
                                                onClick={() => handleOpenConfirmDialog(selectedHierarchy?.id)}
                                                color="error"
                                                title="階層を削除"
                                                sx={{ ml: 1 }}
                                                disabled={!selectedHierarchy}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>

                                    {/* 階層の削除を確認するダイアログ */}
                                    <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                                        <DialogTitle>階層の削除</DialogTitle>
                                        <DialogContent>
                                            <Typography>この階層を削除してもよろしいですか？</Typography>
                                            <Typography sx={{ mt: 2 }}>削除されると、この階層に含まれるファイルもすべて削除されます。</Typography>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => setOpenConfirmDialog(false)}>キャンセル</Button>
                                            <Button onClick={() => handleDeleteHierarchy(deleteTargetId)} color="primary">削除</Button>
                                        </DialogActions>
                                    </Dialog>

                                    {/* 階層名変更ダイアログ */}
                                    <Dialog open={openRenameDialog} onClose={() => setOpenRenameDialog(false)}>
                                        <DialogTitle>階層名の変更</DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="新しい階層名"
                                                type="text"
                                                fullWidth
                                                value={renameValue}
                                                onChange={(e) => setRenameValue(e.target.value)}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => setOpenRenameDialog(false)}>キャンセル</Button>
                                            <Button onClick={() => handleRename()} color="primary">変更</Button>
                                        </DialogActions>
                                    </Dialog>

                                    {/* 新しい階層追加ダイアログ */}
                                    <Dialog open={openDialog} onClose={handleCloseDialog}>
                                        <DialogTitle>新しい階層の追加</DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="階層名"
                                                type="text"
                                                fullWidth
                                                value={newHierarchyName}
                                                onChange={(e) => setNewHierarchyName(e.target.value)}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseDialog}>キャンセル</Button>
                                            <Button onClick={handleAdd} color="primary">追加</Button>
                                        </DialogActions>
                                    </Dialog>

                                    {/* 階層ツリービュー */}
                                    <HierarchyTreeView
                                        hierarchyData={hierarchyData}
                                        selectedHierarchyId={selectedHierarchyId}
                                        handleSelectHierarchy={handleSelectHierarchy}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* ファイルリスト表示 */}
                        < Grid item xs={12} md={8} >
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        ファイルリスト ({selectedHierarchy ? selectedHierarchy.name : 'All Files'})
                                    </Typography>
                                    <Button onClick={toggleSelectAll} variant="outlined">
                                        {checkedFiles.size === fileList.length ? '全解除' : '全選択'}
                                    </Button>
                                    <FileDeleteButton
                                        fetchFileList={() => fetchFileList(selectedHierarchy.name)}
                                    />
                                    <FileUpdateButton
                                        selectedHierarchy={selectedHierarchy}
                                        userId={userId}
                                        fetchFileList={() => fetchFileList(selectedHierarchy.name)}
                                        fileList={fileList}
                                        checkedFiles={checkedFiles}
                                    />
                                    <IconButton
                                        onClick={() => handleOpenRenameDialog()}
                                        disabled={checkedFiles.size !== 1}
                                        style={{ color: checkedFiles.size === 1 ? 'green' : 'grey' }}
                                    >
                                        <EditIcon />
                                        <Typography variant="body2" style={{ marginLeft: 4 }}>編集</Typography>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleOpenMoveDialog()}
                                        sx={{ ml: 1 }}
                                        disabled={checkedFiles.size < 1}
                                        style={{ color: checkedFiles.size >= 1 ? 'purple' : 'grey' }}
                                    >
                                        <MoveIcon />
                                        <Typography variant="body2" style={{ marginLeft: 4 }}>移動</Typography>
                                    </IconButton>
                                    <IconButton
                                        onClick={handleOpenRetrieveChromaDialog}
                                        sx={{ ml: 1 }}
                                        disabled={checkedFiles.size < 1}
                                        style={{ color: checkedFiles.size >= 1 ? 'blue' : 'grey' }}
                                    >
                                        <RefreshIcon />
                                        <Typography variant="body2" style={{ marginLeft: 4 }}>Chroma再取得</Typography>
                                    </IconButton>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            indeterminate={isIndeterminate}
                                                            checked={isAllSelected}
                                                            onChange={toggleSelectAll}
                                                        />
                                                    </TableCell>
                                                    <TableCell>ファイル名</TableCell>
                                                    <TableCell align="right">登録者</TableCell>
                                                    <TableCell align="right">登録日時</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {fileList.map((file) => (
                                                    <TableRow
                                                        key={file.file_name}
                                                        selected={checkedFiles.has(file.file_name)}
                                                        hover
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                checked={checkedFiles.has(file.file_name)}
                                                                onChange={() => toggleFileSelection(file.file_name)}
                                                                color="primary"
                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <Typography
                                                                style={{ cursor: 'pointer', color: '#1976d2', textDecoration: 'underline' }}
                                                                onClick={() => handleDownload(file.file_name, selectedHierarchy.name)}
                                                            >
                                                                {file.title}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right">{file.uploader}</TableCell>
                                                        <TableCell align="right">{file.uploadDate}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            multiple
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            id="file-upload"
                                            accept=".pdf"
                                        />
                                        <label htmlFor="file-upload">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                startIcon={<UpdateIcon />}
                                            >
                                                ファイルを選択
                                            </Button>
                                        </label>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleFileUpload}
                                            style={{ marginLeft: 20 }}
                                            disabled={!selectedFiles.length}
                                        >
                                            アップロード
                                        </Button>
                                        {selectedFiles.map((file, index) => (
                                            <div key={index} style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}>
                                                <Typography variant="body2" style={{ flexGrow: 1 }}>{file.name}</Typography>
                                                <IconButton onClick={() => handleRemoveFile(file.name)} color="error">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        ))}
                                        {loading && (
                                            <Box sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                zIndex: 2,
                                                padding: '30px',
                                                backgroundColor: '#f8f8f8',
                                                borderRadius: '12px',
                                                boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <CircularProgress style={{ animationDuration: '550ms' }} />
                                                <p style={{
                                                    marginTop: '15px',
                                                    fontSize: '18px',
                                                    fontWeight: '500',
                                                    color: '#333',
                                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                }}>
                                                    {loadingMessage}
                                                </p>
                                            </Box>
                                        )}
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid >
                    </Grid >

                    {/* MoveFileDialog コンポーネントの呼び出し */}
                    < MoveFileDialog
                        open={moveDialogOpen}
                        onClose={handleCloseMoveDialog}
                        file={fileToMove}
                        hierarchyData={hierarchyData}
                        onMove={handleMoveFile}
                        fileList={fileList}
                        checkedFiles={checkedFiles}
                    />

                    {/* RenameFileDialog コンポーネントの呼び出し */}
                    < RenameFileDialog
                        open={renameDialogOpen}
                        onClose={handleCloseRenameDialog}
                        file={currentFile}
                        onRename={handleRenameFile}
                    />

                    {/* AddHierarchyDialog コンポーネントの呼び出し */}
                    <AddHierarchyDialog
                        open={openAddDialog}
                        onClose={handleCloseAddDialog}
                        onAdd={handleAddHierarchy}
                        parentId={selectedHierarchyId}
                    />

                    {/* 移動成功ダイアログの呼び出し */}
                    <MoveSuccessDialog
                        open={moveSuccessOpen}
                        onClose={() => setMoveSuccessOpen(false)}
                        fetchFileList={() => fetchFileList(selectedHierarchy.name)}
                    />

                    {/* ファイル更新成功ダイアログの呼び出し */}
                    <UpdateSuccessDialog
                        open={updateSuccessOpen}
                        onClose={() => setUpdateSuccessOpen(false)}
                        fetchFileList={() => fetchFileList(selectedHierarchy.name)}
                    />

                    {/* ファイル編集成功ダイアログの呼び出し */}
                    <EditSuccessDialog
                        open={editSuccessOpen}
                        onClose={() => setEditSuccessOpen(false)}
                        fetchFileList={() => fetchFileList(selectedHierarchy.name)}
                    />
                                
                    {/* Chroma再取得確認ダイアログの呼び出し */}
                    <ConfirmRetrieveChromaDialog
                        open={retrieveChromaDialogOpen}
                        onClose={handleCloseRetrieveChromaDialog}
                        onConfirm={handleRetrieveChroma}
                    />

                    {/* Chroma再取得成功通知ダイアログの呼び出し */}
                    <RetrieveChromaSuccessDialog
                        open={retrieveChromaSuccessOpen}
                        onClose={handleCloseRetrieveChromaSuccessDialog}
                    />

                </Box >
            </Grid >
        </div >
    );
}
