import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReplyIcon from '@mui/icons-material/Reply';
import { Badge, Button, IconButton, MenuItem, MenuList, Popover } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { React, useState } from 'react';
import { useCookies } from 'react-cookie';
import { isMobile } from 'react-device-detect';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CustomLogger from '../logger/Logger';

function Header({
    notificationCount,
    id,
    iconRef,
    arrowLeft,
    notifications,
    userId,
    userData,
    categories
}) {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const logger = new CustomLogger();
    const [cookies, setCookie, removeCookie] = useCookies(['token', 'refresh_token']);
    const [newToken, setNewToken] = useState("");
    const [loading, setLoading] = useState(false);
    const isAdmin = userData.role === 'admin';

    // ログアウト
    function LogOutUser() {
        console.log("ログアウトします");
        removeCookie("token")
        removeCookie("refresh_token")
        navigate('/')
    };

    // 質問画面
    function MoveQuestion() {
        console.log("質問画面に遷移します");
        navigate('/question', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
                categories: categories
            }
        })
    };

    // トップ画面に戻る
    const moveTop = async () => {
        if (cookies.token !== "") {
            console.log("トップ画面に戻る");
            try {
                const res = await axios.get(`/backend/me`, {
                    data: {},
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + cookies.token,
                    },
                });
                console.log(res);
                if (res.data.login_ok === "login_ok") {
                    console.log(res.data);
                    navigate("/top", {
                        state: {
                            userId: res.data.id,
                        },
                    });
                }
            } catch (error) {
                // トークンの有効期限が切れている場合、リフレッシュトークンを再取得する
                if (error.response && error.response.status === 401) {
                    logger.debug("トークンの有効期限が切れているため、リフレッシュトークンを再取得します。");
                    try {
                        const res = await axios.get(`/backend/refresh`, {
                            data: {},
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + cookies.refresh_token,
                            },
                        });

                        console.log(res);
                        removeCookie("token");
                        setCookie("token", res.data.access_token);
                        setNewToken(res.data.access_token);

                        // 再取得したトークンでtop画面に戻る
                        const updatedToken = res.data.access_token;
                        await new Promise((resolve) => setTimeout(resolve, 0));
                        const res2 = await axios.get(`/backend/me`, {
                            data: {},
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + updatedToken,
                            },
                        });

                        console.log(res2);
                        if (res2.data.login_ok === "login_ok") {
                            console.log(res2.data);
                            console.log("トップ画面に戻る");
                            navigate("/top", {
                                state: {
                                    userId: res2.data.id,
                                },
                            });
                        }
                    } catch (error) {
                        logger.error("トークンの再取得に失敗しました。", error);
                    }
                }
            }
        }
    };

    // 回答画面に移動する
    const navigateToAnswer = (userId, questionId, notificationCount, notifications, userData) => {
        console.log("回答画面に遷移します");
        navigate('/answer', {
            state: {
                userId: userId,
                questionId: questionId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
            },
            replace: true,
        });
    };

    // メニューを開く
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // メニューを閉じる
    const handleClose = () => {
        setAnchorEl(null);
    };

    // メニューを開く
    const handleUserMenuClick = (event) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    // メニューを閉じる
    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    // プロフィールページに移動
    const moveProfile = () => {
        console.log("プロフィールページに遷移します");
        navigate('/profile', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications
            }
        })
    };

    // 管理画面に移動
    const moveToManagement = () => {
        console.log("管理画面に遷移します");
        navigate('/management', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications
            }
        })
    };

    // 通知をクリックしたときの処理
    const handleItemClick = (notificationId) => {
        console.log("Clicked item with id:", notificationId);
        async function AlreadyRead() {
            console.log("既読にします");
            const res = await axios.post(
                `/backend/notificationasread`,
                {
                    notification_id: notificationId,
                    user_id: userId,
                    withCredentials: true
                }
            );
            console.log(res)
            window.location.reload();
        }
        AlreadyRead();
    };

    // 検索結果画面に移動
    const handleInputChange = (e) => {
        setSearchText(e.target.value);
    };

    // 検索結果画面に移動
    async function handleKeyPress(e) {
        if (e.key === 'Enter') {
            console.log(`Sending ${searchText} to the backend...`);
            try {
                setLoading(true);
                console.log('検索します：' + searchText)
                const res = await axios.post(`/backend/search`,
                    {
                        searchText: searchText,
                        withCredentials: true
                    })
                console.log(res)

                // 検索結果を表示する画面に遷移
                console.log('検索結果を表示します')
                console.log(categories)
                navigate('/searched', {
                    state: {
                        userId: userId,
                        searchText: searchText,
                        questions: res.data.data,
                        notificationCount: notificationCount,
                        notifications: notifications,
                        userData: userData,
                        category: categories
                    },
                    replace: true,
                });
            } catch (error) {
                logger.error('データ送信中にエラーが発生しました:', error);
                navigate('/searched', {
                    state: {
                        userId: userId,
                        searchText: searchText,
                        questions: [],
                        notificationCount: notificationCount,
                        notifications: notifications,
                        userData: userData,
                        category: categories,
                        errorMessage: '検索に失敗しました。\n質問文を書く形で検索してみてください。'
                    },
                    replace: true,
                });
            } finally {
                setLoading(false);
            }
        }
    };

    // 検索結果画面に移動
    async function handleIconClick(e) {
        console.log(`Sending ${searchText} to the backend...`);
        try {
            console.log('検索します：' + searchText)
            setLoading(true);
            const res = await axios.post(`/backend/search`,
                {
                    searchText: searchText,
                    withCredentials: true
                })
            console.log(res)

            // 検索結果を表示する画面に遷移
            console.log('検索結果を表示します')
            navigate('/searched', {
                state: {
                    userId: userId,
                    searchText: searchText,
                    questions: res.data.data,
                    notificationCount: notificationCount,
                    notifications: notifications,
                    userData: userData,
                    category: categories
                },
                replace: true,
            });
        } catch (error) {
            logger.error('データ送信中にエラーが発生しました:', error);
            navigate('/searched', {
                state: {
                    userId: userId,
                    searchText: searchText,
                    questions: [],
                    notificationCount: notificationCount,
                    notifications: notifications,
                    userData: userData,
                    category: categories,
                    errorMessage: '検索に失敗しました。\n質問文を書く形でみて検索してください。'
                },
                replace: true,
            });
        } finally {
            setLoading(false);
        }
    };

    // 一括既読を実行する関数
    const markAllAsRead = async () => {
        try {
            const res = await axios.post(
                `/backend/markAllNotificationsAsRead`,
                { user_id: userId },
                { withCredentials: true }
            );
            console.log(res);
            window.location.reload();
        } catch (error) {
            console.error("Error marking all notifications as read:", error);
        }
    };

    // 日時をフォーマットする関数
    function formatDate(dateString) {
        // UTCからJST（UTC+9時間）へ変換
        const utcDate = new Date(dateString);
        const jstDate = new Date(utcDate.getTime() + (9 * 60 * 60 * 1000));

        // ローカル時刻もJSTへ変換
        const now = new Date();
        now.setTime(now.getTime() + (9 * 60 * 60 * 1000));

        const diffInSeconds = Math.round((now - jstDate) / 1000);
        const diffInMinutes = Math.round(diffInSeconds / 60);
        const diffInHours = Math.round(diffInMinutes / 60);
        const diffInDays = Math.round(diffInHours / 24);

        if (diffInSeconds < 60) {
            return 'Just now';
        } else if (diffInMinutes < 60) {
            return `${diffInMinutes} minutes ago`;
        } else if (diffInHours < 24) {
            return `${diffInHours} hours ago`;
        } else {
            return `${diffInDays} days ago`;
        }
    }

    return (
        <AppBar
            position="static"
            color='inherit'
            sx={{ height: { xs: 'auto', sm: '55px' } }}
            elevation={0}
        >
            <Toolbar>
                <Grid container direction="row" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={3} order={isMobile ? 2 : 1}>
                        <Box textAlign="right" sx={{ mr: { xs: 1, sm: '5%' } }}>
                            <img
                                src="Qalogo.png"
                                alt="QA System"
                                style={{ maxWidth: '100%', height: 'auto' }}
                                onClick={moveTop}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} order={isMobile ? 1 : 3} display="flex" justifyContent="flex-end">
                        <Box display="flex" alignItems="center">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ContactSupportIcon />}
                                onClick={() => MoveQuestion()}
                                sx={{ width: '130px', height: '50px' }}
                            >
                                <Typography style={{ fontWeight: 'bold' }}>
                                    質問する
                                </Typography>
                            </Button>

                            <IconButton
                                edge="end"
                                color="inherit"
                                sx={{ ml: 1, '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.2)', boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.2) !important' } }}
                                onClick={handleClick}
                            >
                                <Badge badgeContent={notificationCount} color="error">
                                    <NotificationsIcon ref={iconRef} sx={{ fontSize: 35 }} />
                                </Badge>
                            </IconButton>

                            <IconButton edge="end" color="inherit" onClick={handleUserMenuClick} sx={{
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                    boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.2) !important',
                                }
                            }}>
                                {userData.icon && userData.icon !== "null"
                                    ? <img src={userData.icon} alt="User icon" style={{ height: 35, width: 35, }} />
                                    : <PersonIcon style={{ fontSize: 35 }} />
                                }
                            </IconButton>

                            <Popover
                                id={id}
                                open={open}
                                anchorEl={iconRef.current}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                PaperProps={{
                                    style: {
                                        borderRadius: "4px",
                                        overflow: "visible",
                                        boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                                        width: "auto",
                                        maxWidth: "100%",
                                        minWidth: "300px",
                                        border: '2px solid black',
                                        marginTop: '40px',
                                        backgroundColor: "#f5f5f5",
                                        maxHeight: '500px',
                                        overflowY: 'auto',
                                    },
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px', backgroundColor: '#e3e3e3' }}>
                                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>通知</Typography>
                                    <Button onClick={markAllAsRead} style={{ marginRight: '8px' }}>一括既読</Button>
                                </div>
                                <MenuList style={{ width: 'auto', maxWidth: '100%', padding: '8px', borderTop: '1px solid gray' }}>
                            {/* 質問セクション */}
                            <Typography variant="h6" style={{ padding: '8px', backgroundColor: '#e7f0fd' }}>質問</Typography>
                            {notifications
                                .filter(notification => !notification.responder_id)
                                .map((notification) => {
                                    // ユーザーが匿名かどうかを判定
                                    const isUserAnonymous = notification.question_is_anonymous && !isAdmin;

                                    // 表示するユーザー名を決定
                                    const displayUserName = notification.question_is_anonymous
                                        ? isAdmin
                                            ? `${notification.questioner_name || notification.questioner} (匿名投稿)`
                                            : '匿名ユーザー'
                                        : notification.questioner_name || notification.questioner;

                                    return (
                                        <MenuItem
                                            key={notification.id}
                                            onClick={() => handleItemClick(notification.id)}
                                            style={{ wordBreak: 'break-word', backgroundColor: '#ffffff', padding: '10px', margin: '5px 0', borderRadius: '4px' }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <QuestionAnswerIcon style={{ marginRight: '8px' }} />
                                                <div>
                                                    {`${displayUserName}から新しい質問が投稿されました！`}
                                                    <Typography
                                                        component={RouterLink}
                                                        to="#"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            navigateToAnswer(
                                                                userId,
                                                                notification.question_id,
                                                                notificationCount,
                                                                notifications,
                                                                userData
                                                            );
                                                            handleItemClick(notification.id);
                                                        }}
                                                        style={{ wordBreak: 'break-word' }}
                                                    >
                                                        {notification.question_title}
                                                    </Typography>
                                                    <div style={{ fontSize: '0.8em', color: 'gray' }}>
                                                        {formatDate(notification.created_at)}
                                                    </div>
                                                </div>
                                            </div>
                                        </MenuItem>
                                    );
                                })
                            }
                            {/* 回答セクション */}
                            <Typography variant="h6" style={{ padding: '8px', backgroundColor: '#fff0f0' }}>回答</Typography>
                            {notifications
                                .filter(notification => notification.responder_id)
                                .map((notification) => {
                                    // 回答者が匿名かどうかを判定
                                    const isUserAnonymous = notification.answer_is_anonymous && !isAdmin;

                                    // 表示するユーザー名を決定
                                    const displayUserName = notification.answer_is_anonymous
                                        ? isAdmin
                                            ? `${notification.responder_name || notification.responder_id} (匿名投稿)`
                                            : '匿名ユーザー'
                                        : notification.responder_name || notification.responder_id;

                                    return (
                                        <MenuItem
                                            key={notification.id}
                                            onClick={() => handleItemClick(notification.id)}
                                            style={{ wordBreak: 'break-word', backgroundColor: '#ffffff', padding: '10px', margin: '5px 0', borderRadius: '4px' }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <ReplyIcon style={{ marginRight: '8px' }} />
                                                <div>
                                                    {`${displayUserName}さんが`}
                                                    <Typography
                                                        component={RouterLink}
                                                        to="#"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            navigateToAnswer(
                                                                userId,
                                                                notification.question_id,
                                                                notificationCount,
                                                                notifications,
                                                                userData
                                                            );
                                                            handleItemClick(notification.id);
                                                        }}
                                                        style={{ wordBreak: 'break-word', display: 'inline' }}
                                                    >
                                                        {`「${notification.question_title}」`}
                                                    </Typography>
                                                    {`に回答しました。`}
                                                    <div style={{ fontSize: '0.8em', color: 'gray' }}>
                                                        {formatDate(notification.created_at)}
                                                    </div>
                                                </div>
                                            </div>
                                        </MenuItem>
                                    );
                                })
                            }
                        </MenuList>
                            </Popover>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6" sx={{ ml: 1 }}>
                                    {userData.name || userId}
                                </Typography>
                            </Box>
                        </Box>

                        <Menu
                            anchorEl={userMenuAnchorEl}
                            open={Boolean(userMenuAnchorEl)}
                            onClose={handleUserMenuClose}
                        >
                            <Typography variant="h6" style={{ padding: '8px', fontWeight: 'bold' }}>ユーザーメニュー</Typography>
                            <MenuItem onClick={moveProfile}>プロフィール</MenuItem>
                            {/* 管理者またはマネージャーの場合にのみ表示 */}
                            {(userData.role === 'admin' || userData.role === 'manager') && (
                                <MenuItem onClick={moveToManagement}>管理画面</MenuItem>
                            )}
                            <MenuItem onClick={LogOutUser}>ログアウト</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Toolbar>
            <Backdrop open={loading} style={{ color: '#fff', zIndex: 1500 }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <CircularProgress color="inherit" />
                    <p style={{
                        marginTop: '20px',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: 'white',
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}>
                        検索中です...
                    </p>
                </div>
            </Backdrop>
        </AppBar>
    );
}

export default Header;
