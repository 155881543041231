import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { React, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from 'react-router-dom';
import ChatButton from '../chatbot/ChatButton';
import ChatWindow from '../chatbot/ChatWindow';
import Consent from '../consent/Consent';
import Header from '../header/Header';
import MenuButtons from '../menubuttons/MenuButtons';
import QuestionsList from './QuestionsList';

export default function Top() {
    const location = useLocation();
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const iconRef = useRef(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const [notificationCount, setNotificationCount] = useState(0);
    const [chatOpen, setChatOpen] = useState(false);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [notifications, setNotifications] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies();
    const [isFirstEffectCompleted, setFirstEffectCompleted] = useState(false);
    const [userData, setUserData] = useState({
        id: userId,
        password: '',
        icon: '',
        name: '',
        affiliation: '',
        skill: ''
    });
    const [categories, setCategories] = useState(null);
    const [consentGiven, setConsentGiven] = useState(false);
    const [showConsentModal, setShowConsentModal] = useState(false);

    // ユーザーの同意状況を確認
    const checkConsentStatus = async () => {
        const consentCookie = cookies.consent;
        if (consentCookie) {
            const lastConsentDate = new Date(consentCookie);
            const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
            if (lastConsentDate < thirtyDaysAgo) {
                setShowConsentModal(true);
            } else {
                setConsentGiven(true);
            }
        } else {
            setShowConsentModal(true);
        }
    };

    // 同意を保存
    const handleConsent = async () => {
        setCookie('consent', new Date().toISOString(), { path: '/' });
        setConsentGiven(true);
        setShowConsentModal(false);
    };

    // ユーザーデータを取得
    useEffect(() => {
        async function fetchData() {
            // 7文字のランダムな文字列を生成
            function generateRandomString(length) {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let result = '';
                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * characters.length));
                }
                return result;
            }

            // 暗号化されたものを復号化（CryptoJS使用）
            function aesDecryptCryptoJS(encryptedData, keyStr, ivStr) {
                const keyWordArray = CryptoJS.enc.Base64.parse(keyStr);
                const ivWordArray = CryptoJS.enc.Base64.parse(ivStr);

                const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, keyWordArray, {
                    iv: ivWordArray,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                });

                const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
                return decryptedText;
            }

            // ユーザーデータを取得
            if (location.state && location.state.userId) {
                // location.stateが存在する場合は、その値を使用
                setUserId(location.state.userId);
                setFirstEffectCompleted(true);
                checkConsentStatus();
            } else {
                  // URLからemployeeNumberのクエリパラメータを取得
                  const queryString = new URLSearchParams(window.location.search);
                  const keyBase64 = btoa('12345678901234567890123456789012');
                  const encodedEncryptedEmployeeNumber = decodeURIComponent(queryString.get('employeeNumber'));
                  const ivBase64 = decodeURIComponent(queryString.get('iv'));

                  // 両方のパラメータが存在しない場合、エラーページに遷移
                  if (!encodedEncryptedEmployeeNumber && !ivBase64) {
                      console.log('クエリパラメータが存在しません');
                      navigate('/authfailure');
                      return;
                  }

                  // CryptoJSの復号化関数を使用してemployeeNumberFromParamを取得
                  const employeeNumberFromParam = aesDecryptCryptoJS(encodedEncryptedEmployeeNumber, keyBase64, ivBase64);
                  console.log('Decrypted text:', employeeNumberFromParam);
                try {
  

                    // クエリパラメータが存在しない場合は、サーバーに問い合わせる
                    const response = await axios.post(
                        '/backend/getcookie',
                        {
                            employeeNumber: employeeNumberFromParam,
                            withCredentials: true
                        });
                    console.log(response);
                    setCookie("token", response.data.access_token);
                    setCookie("refresh_token", response.data.refresh_token);

                    if (response.data.status === 'exists') {
                        setUserId(response.data.userId);
                        checkConsentStatus();
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        // 7文字のランダムな文字列を生成
                        const randomPassword = generateRandomString(7);
                        let userData = {
                            id: employeeNumberFromParam,
                            password: randomPassword,
                            icon: 'null',
                            name: '',
                            affiliation: '',
                            skill: '',
                            skill_level: 5
                        };

                        // ユーザーデータを登録
                        const res = await axios.post(
                            `/backend/adduserdata`,
                            {
                                userData: userData,
                            }, {
                            withCredentials: true
                        });
                        console.log(res);

                        // トップページにで必要な情報を取得
                        const resTop = await axios.post(
                            `/backend/totop`,
                            {
                                id: userData.id,
                            }, {
                            withCredentials: true
                        });
                        console.log(resTop);
                        setCookie("token", resTop.data.access_token);
                        setCookie("refresh_token", resTop.data.refresh_token);

                        // chatgptユーザー設定を登録
                        console.log(userData.id);
                        const settingRes = await axios.post(
                            `/backend/save_user_settings`,
                            {
                                userId: Number(userData.id),
                                model: "gpt-3.5-turbo-16k",
                                historyCount: 0,
                                temperature: 1.0,
                            },
                            { withCredentials: true }
                        );
                        console.log(settingRes);

                        // 通知情報を取得
                        const resNotification = await axios.post(`/backend/getnotifications`, {
                            user_id: userData.id,
                            withCredentials: true
                        });
                        console.log(resNotification);
                        const notificationsData = resNotification.data;
                        const notificationCountData = notificationsData.length;

                        // ユーザーページに遷移
                        navigate('/user', {
                            state: {
                                userId: userData.id,
                                notificationCount: notificationCountData,
                                notifications: notificationsData
                            }
                        });
                        checkConsentStatus();
                        setFirstEffectCompleted(true);
                    } else {
                        console.error('Error fetching data: ', error);
                        navigate('/authfailure');
                    }
                }   
            }
        }
        fetchData();
    }, [location, navigate, setCookie]);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log('質問を取得します');
        async function fetchQuestion() {
            const res = await axios.post(
                `/backend/getquestions`,
                {
                    withCredentials: true
                }
            );
            console.log(res);
            setQuestions(res.data);
        }

        // ユーザーの同意が得られた場合のみ質問を取得
        if (consentGiven) {
            fetchQuestion();
        }
    }, [consentGiven]);

    // ユーザーデータを取得
    useEffect(() => {
        console.log('ユーザーデータを取得します');
        async function fetchUserData() {
            console.log('userId:', userId);
            try {
                if (!userId) {
                    return;
                }
                const res = await axios.post(`/backend/getuserdata`, {
                    user_id: userId,
                    withCredentials: true
                });
                console.log(res);
                setUserData(res.data.user);
            } catch (error) {
                console.error('ユーザーデータの取得中にエラーが発生しました:', error);
            }
        }

        // ユーザーの同意が得られた場合のみユーザーデータを取得
        if (consentGiven) {
            fetchUserData();
        }
    }, [consentGiven, userId]);

    // 通知を取得
    useEffect(() => {
        console.log('通知を取得します');
        async function fetchNotification() {
            try {
                if (!userId) {
                    return;
                }
                const res = await axios.post(`/backend/getnotifications`, {
                    user_id: userId,
                    withCredentials: true
                });
                console.log(res);
                setNotifications(res.data);
                setNotificationCount(res.data.length);
            } catch (error) {
                console.error('通知の取得中にエラーが発生しました:', error);
            }
        }

        // ユーザーの同意が得られた場合のみ通知を取得
        if (consentGiven) {
            fetchNotification();
        }
    }, [consentGiven, userId]);

    // カテゴリを取得
    useEffect(() => {
        console.log('カテゴリを取得します');
        async function fetchCategory() {
            const res = await axios.post(
                `/backend/getcategories`,
                {
                    withCredentials: true
                }
            );
            console.log(res);
            setCategories(res.data);
        }

        // ユーザーの同意が得られた場合のみカテゴリを取得
        if (consentGiven) {
            fetchCategory();
        }
    }, [consentGiven]);

    // アイコンの位置を更新
    useEffect(() => {
        const updateArrowPosition = () => {
            if (iconRef.current) {
                const iconRect = iconRef.current.getBoundingClientRect();
                setArrowLeft(iconRect.left + iconRect.width / 2 - 8);
            }
        };

        // ウィンドウのリサイズ時にアイコンの位置を更新
        window.addEventListener('resize', updateArrowPosition);
        updateArrowPosition();

        return () => {
            window.removeEventListener('resize', updateArrowPosition);
        };
    }, []);

    // サービスワーカーの登録
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope);
                })
                .catch((err) => {
                    console.error('Service Worker registration failed:', err);
                });
        }
    }, []);

    // 通知の許可を求める
    useEffect(() => {
        const askForNotificationPermission = () => {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                }
            });
        };

        // ブラウザが通知をサポートしているか確認
        if (!('Notification' in window)) {
            console.log('This browser does not support notifications.');
        } else {
            if (Notification.permission === 'granted') {
                console.log('Permission has already been granted');
            } else if (Notification.permission !== 'denied') {
                askForNotificationPermission();
            }
        }
    }, []);

    // プッシュ通知の購読
    useEffect(() => {
        if (!('serviceWorker' in navigator && 'PushManager' in window)) {
            console.warn('Service Workers or PushManager are not supported in this browser.');
            return;
        }

        // サービスワーカーを処理
        const handleServiceWorker = async () => {
            try {
                const registration = await navigator.serviceWorker.ready;
                const subscription = await registration.pushManager.getSubscription();

                //
                if (subscription) {
                    try {
                        await subscription.unsubscribe();
                        console.log('Unsubscribed!');
                    } catch (e) {
                        console.log('Failed to unsubscribe.', e);
                    }
                }

                // サーバーから公開鍵を取得
                const response = await axios.get(`/backendpublicKey`, {
                    withCredentials: true
                });

                // サーバーから公開鍵を取得
                const serverPublicKey = response.data.publicKey;

                // Base64URL形式の公開鍵かどうかを確認
                if (/^[-A-Za-z0-9_=]*$/.test(serverPublicKey)) {
                    console.log("The public key is in Base64URL format.");
                } else {
                    console.log("The public key is not in Base64URL format.");
                    return;
                }

                // 公開鍵をUint8Arrayに変換
                const convertedVapidKey = urlBase64ToUint8Array(serverPublicKey);

                // プッシュ通知の購読
                try {
                    const subscription = await registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: convertedVapidKey,
                    });

                    console.log('Subscription:', convertedVapidKey);
                    await axios.post(
                        `/backend/subscribe`,
                        {
                            user_id: userId,
                            subscription: subscription,
                            withCredentials: true
                        }
                    );
                } catch (error) {
                    console.error("Error during pushManager subscription:", error);
                }
            } catch (error) {
                console.error("Error handling service worker:", error);
            }
        };

        // Base64URL形式の文字列をUint8Arrayに変換
        const urlBase64ToUint8Array = (base64String) => {
            const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
            const rawData = atob(base64);
            const outputArray = new Uint8Array(rawData.length);
            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        };

        handleServiceWorker();
    }, []);

    // ユーザーの同意が得られた場合のみ画面を表示
    if (showConsentModal) {
        return <Consent onConsent={handleConsent} />;
    }

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={categories}
                />
                <MenuButtons
                    navigate={navigate}
                    userId={userId}
                    buttonType={'トップ'}
                    notificationCount={notificationCount}
                    notifications={notifications}
                    userData={userData}
                    categories={categories}
                />
            </Box>

            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ flexGrow: 1, backgroundColor: '#F2F2F2' }}
            >
                <Grid
                    item xs={12}
                    sx={{ width: '80%', mt: 5 }}
                >
                    <Box sx={{ backgroundColor: 'white', p: 0, borderRadius: 2, border: '1px solid black', m: 0 }}>
                        <QuestionsList
                            userId={userId}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            questions={questions}
                            userData={userData}
                            notificationCount={notificationCount}
                            notifications={notifications}
                            categories={categories}
                        />
                    </Box>
                </Grid>
            </Grid>

            <Box
                sx={{
                    position: 'fixed',
                    right: 15,
                    bottom: 15,
                    zIndex: 1000,
                }}
            >
                {chatOpen ? (
                    <ChatWindow userId={userId} onClose={() => setChatOpen(false)} />
                ) : (
                    <ChatButton onClick={() => setChatOpen(true)} />
                )}
            </Box>
        </div>
    );
}
