import ChatIcon from '@mui/icons-material/Chat';
import Fab from '@mui/material/Fab';
import React from 'react';

const ChatButton = ({ onClick }) => (
  <Fab color="info" aria-label="chat" onClick={onClick} style={{ height: '80px', width: '80px' }} >
    <ChatIcon style={{ fontSize: 35 }} />
  </Fab>
);

export default ChatButton;
