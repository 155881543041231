import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import 'draft-js/dist/Draft.css';
import { React } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


// 入力された質問をサーバーを介してDBに保存する
function AnswerForm({ handleEditing }) {

    // 必要に応じて、AnswerCommentEditor のプロパティを設定
    const handleClick = () => {
        handleEditing();
    };

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 1,
                    m: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}
            >
                <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                    size="large"
                    sx={{
                        my: 1,
                        p: 2,
                        width: '230px',
                        flexDirection: 'column-reverse'
                    }}
                    onClick={handleClick}
                >
                    回答する
                </Button>
            </Box>
        </div>
    );
};


export default AnswerForm;
