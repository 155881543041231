import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { React, useEffect, useRef, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import ChatButton from '../chatbot/ChatButton';
import ChatWindow from '../chatbot/ChatWindow';
import Header from '../header/Header';
import CustomLogger from '../logger/Logger';
import MenuButtons from '../menubuttons/MenuButtons';
import { skills } from './skills';

export default function Profile() {
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, notificationCount, notifications, category } = location.state || {};
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [chatOpen, setChatOpen] = useState(false);
    const [userData, setUserData] = useState({
        id: userId,
        password: '',
        icon: '',
        name: '',
        affiliation: '',
        skill: '',
        skill_level: ''
    });
    const logger = new CustomLogger();
    const [loading, setLoading] = useState(false);


    // アイコンが変更された時の処理
    const handleIconChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            // バイナリデータに変換した画像データをセット
            setUserData({ ...userData, icon: reader.result });
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    // スキルが変更された時の処理
    const handleSkillChange = (e) => {
        const skill = e.target.value;
        if (e.target.checked) {
            // チェックが入った場合、新しいスキルを追加する
            setUserData((prevUserData) => ({
                ...prevUserData,
                skill: Array.isArray(prevUserData.skill) ? [...prevUserData.skill, skill] : [skill],
            }));
            // 選択されたスキルのリストに新しいスキルを追加
            setSelectedSkills(prev => [...prev, skill]);
        } else {
            // チェックが外れた場合、既存のスキルから削除する
            setUserData((prevUserData) => ({
                ...prevUserData,
                skill: Array.isArray(prevUserData.skill)
                    ? prevUserData.skill.filter((selectedSkill) => selectedSkill !== skill)
                    : [],
            }));
            // 選択されたスキルのリストから削除
            setSelectedSkills(prev => prev.filter(selectedSkill => selectedSkill !== skill));
        }
        console.log(userData.skill);
    };

    // 保存ボタンを押せるかどうかを判定する関数
    const canSaveUserData = () => {
        // チェックされているスキルが存在するかを確認
        return userData.skill !== null && userData.skill.length > 0;
    };

    // 保存ボタンのdisabled属性を設定する値を取得
    const saveButtonDisabled = !canSaveUserData();

    // ユーザーデータを保存する関数
    async function saveUserData() {
        setLoading(true); // ローディング開始
        console.log('保存するデータ:', userData);
        try {
            //ここで実際にDBから取得する
            const res = await axios.post(`/backend/postuserdata`,
                {
                    userData: userData,
                    withCredentials: true
                })
            console.log(res)
        } catch (error) {
            logger.error('データ送信中にエラーが発生しました:', error);
        } finally {
            setLoading(false); // ローディング終了
        }
        // 画面を更新
        window.location.reload();
    };

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        //　バッグエンドから質問を取得する
        console.log('ユーザーデータを取得します')
        async function fetchUserData() {
            const res = await axios.post(
                `/backend/getuserdata`,
                {
                    user_id: userId,
                    withCredentials: true
                }
            );
            console.log(res)
            setUserData(res.data.user);
            let skills = res.data.user.skill;
            if (typeof skills === 'string') {
                try {
                    skills = skills.replace(/^{(.*)}$/, '$1');
                    skills = skills.split(',').map(skill => skill.trim());
                } catch (e) {
                    logger.error('Failed to parse skills:', e);
                }
            }
            if (Array.isArray(skills)) {
                setSelectedSkills(skills.filter(skill => typeof skill === 'string'));
            }
        }
        fetchUserData();
    }, []);

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={category}
                />
                <MenuButtons
                    navigate={navigate}
                    userId={userId}
                    buttonType={'ユーザー'}
                    notificationCount={notificationCount}
                    notifications={notifications}
                    userData={userData}
                    categories={category}
                />
            </Box>

            <Grid container item xs={12} sm={12} md={12} justifyContent="center" alignItems="center"
                sx={{ flexGrow: 1, backgroundColor: '#F2F2F2', height: '100vh', overflow: 'auto' }}>
                {loading && (
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                        <CircularProgress />
                    </Box>
                )}
                {loading && (
                    <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 1 }} />
                )}
                <Grid container item xs={12} justifyContent="center" alignItems="center">
                    <TableContainer component={Paper} sx={{ maxHeight: 880, marginTop: 2 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>アイコン</Typography>
                                        {userData.icon && userData.icon !== "null" ? (
                                            <img src={userData.icon} alt="Icon Preview" style={{ width: '100%', maxWidth: '100px' }} />
                                        ) : (
                                            <Typography variant="body2" style={{ color: 'red' }}>アイコンが設定されていません</Typography>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleIconChange}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>ニックネーム</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            type="text"
                                            value={userData.name}
                                            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>所属</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            type="text"
                                            value={userData.affiliation}
                                            onChange={(e) => setUserData({ ...userData, affiliation: e.target.value })}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>通知</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <select
                                            value={userData.skill_level === 5 ? 'on' : 'off'}
                                            onChange={(e) => {
                                                console.log(e.target.value);
                                                const value = e.target.value === 'on' ? 5 : 2;
                                                setUserData({ ...userData, skill_level: value });
                                            }}
                                        >
                                            <option value='on'>オン</option>
                                            <option value='off'>オフ</option>
                                        </select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>スキル</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {skills.map((skillCategory) => (
                                            <Accordion key={skillCategory.category}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography variant="subtitle1">{skillCategory.category}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <FormGroup>
                                                        {skillCategory.skills.map((skill) => (
                                                            <FormControlLabel
                                                                key={skill}
                                                                control={
                                                                    <Checkbox
                                                                        checked={userData.skill !== null && userData.skill.includes(skill)}
                                                                        onChange={handleSkillChange}
                                                                        value={skill}
                                                                    />
                                                                }
                                                                label={skill}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                        <div style={{ marginTop: '50px' }}>
                                            <Typography variant="h6">選択されたスキル:</Typography>
                                            <div>
                                                {selectedSkills.flatMap(skill => skill.split(', ')).map((skill, index) => (
                                                    <Chip key={index} label={skill.trim()} />
                                                ))}
                                            </div>

                                        </div>
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                        <Button
                            onClick={saveUserData}
                            variant="contained"
                            color="primary"
                            disabled={saveButtonDisabled}
                            sx={{ width: '200px', height: '50px' }}
                        >
                            保存
                        </Button>
                    </Box>
                </Grid >
            </Grid >
            <Box
                sx={{
                    position: 'fixed',
                    right: 15,
                    bottom: 15,
                    zIndex: 1000,
                }}
            >
                {chatOpen ? (
                    <ChatWindow userId={userId} onClose={() => setChatOpen(false)} />
                ) : (
                    <ChatButton onClick={() => setChatOpen(true)} />
                )}
            </Box>
        </div >
    );
};
