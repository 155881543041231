// MenuButtons.js
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import axios from 'axios';
import { React, useState } from 'react';
import { useCookies } from "react-cookie";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CustomLogger from '../logger/Logger';

const MenuButtons = ({ navigate, userId, buttonType, notificationCount, notifications, userData, categories }) => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [newToken, setNewToken] = useState("");
    const [selectedButton, setSelectedButton] = useState(buttonType);
    const logger = new CustomLogger();
    const handleClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    // 質問一覧画面に移動する
    const moveQuestions = () => {
        console.log("質問一覧画面に移動する");
        navigate('/questions', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
                category: categories
            }
        })
    };

    // 回答画面に移動する
    const moveAnswers = () => {
        console.log("回答画面に移動する");
        navigate('/answers', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
                category: categories
            }
        })
    };

    // ユーザー画面に移動する
    const moveUsers = () => {
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: userId
            }
        })
    };

    // トップ画面に戻る
    const moveTop = async () => {
        if (cookies.token !== "") {
            console.log("トップ画面に戻る");
            try {
                const res = await axios.get(`/backend/me`, {
                    data: {},
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + cookies.token,
                    },
                });
                console.log(res);
                if (res.data.login_ok === "login_ok") {
                    console.log(res.data);
                    navigate("/top", {
                        state: {
                            userId: res.data.id
                        },
                    });
                }
            } catch (error) {
                // トークンの有効期限が切れている場合、リフレッシュトークンを再取得する
                if (error.response.data.msg === "Token has expired") {
                    logger.debug("トークンの有効期限が切れているため、リフレッシュトークンを再取得します。");
                    try {
                        const res = await axios.get(`/backend/refresh`, {
                            data: {},
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + cookies.refresh_token,
                            },
                        });

                        console.log(res);
                        removeCookie("token");
                        setCookie("token", res.data.access_token);
                        setNewToken(res.data.access_token);

                        // 再取得したトークンでtop画面に戻る
                        const updatedToken = res.data.access_token;
                        await new Promise((resolve) => setTimeout(resolve, 0));
                        const res2 = await axios.get(`/backend/me`, {
                            data: {},
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + updatedToken,
                            },
                        });

                        console.log(res2);
                        if (res2.data.login_ok === "login_ok") {
                            console.log(res2.data);
                            console.log("トップ画面に戻る");
                            navigate("/top", {
                                state: {
                                    userId: res2.data.id,
                                },
                            });
                        }
                    } catch (error) {
                        logger.error("トークンの再取得に失敗しました。", error);
                    }
                }
            }
        }
    };


    return (
        <Box
            display="flex"
            justifyContent="flex-start"
            my={0}
            mx="auto"
            width="100%"
            padding="35px"
        >
            <Button
                size="small"
                onClick={() => { handleClick('トップ'); moveTop(); }}
                sx={{
                    width: "20%",
                    fontSize: "90%",
                    color: "black",
                    fontWeight: 'bold',
                    borderBottom: selectedButton === 'トップ' ? '4px solid #1976d2' : 'none',
                    marginRight: '10px',
                    '&:hover': {
                        borderBottom: '4px solid #1976d2',
                    }
                }}
            >
                トップ
            </Button>
            <Button
                size="small"
                onClick={() => { handleClick('質問'); moveQuestions(); }}
                sx={{
                    width: "20%",
                    fontSize: "90%",
                    color: "black",
                    fontWeight: 'bold',
                    borderBottom: selectedButton === '質問一覧' ? '4px solid #1976d2' : 'none',
                    marginRight: '10px',
                    '&:hover': {
                        borderBottom: '4px solid #1976d2',
                    }
                }}
            >
                質問一覧
            </Button>
            <Button
                size="small"
                onClick={() => { handleClick('回答'); moveAnswers(); }}
                sx={{
                    width: "20%",
                    fontSize: "90%",
                    color: "black",
                    fontWeight: 'bold',
                    borderBottom: selectedButton === '回答する' ? '4px solid #1976d2' : 'none',
                    marginRight: '10px',
                    '&:hover': {
                        borderBottom: '4px solid #1976d2',
                    }
                }}
            >
                回答する
            </Button>
            <Button
                size="small"
                onClick={() => { handleClick('ユーザー'); moveUsers(); }}
                sx={{
                    width: "20%",
                    fontSize: "90%",
                    color: "black",
                    fontWeight: 'bold',
                    borderBottom: selectedButton === 'ユーザー' ? '4px solid #1976d2' : 'none',
                    marginRight: '10px',
                    '&:hover': {
                        borderBottom: '4px solid #1976d2',
                    }
                }}
            >
                ユーザー
            </Button>
        </Box >
    );
};

export default MenuButtons;
