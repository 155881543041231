import CheckIcon from '@mui/icons-material/Check';
import { Box, Card, Chip, Container, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ChatButton from '../chatbot/ChatButton';
import ChatWindow from '../chatbot/ChatWindow';
import Header from '../header/Header';
import MenuButtons from '../menubuttons/MenuButtons';
import AnswerItem from './AnswerItem';
import CommentItem from './CommentItem';
import QuestionItem from './QuestionItem';
import PaginationControls from './PaginationControls';

export default function User() {
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, notificationCount, notifications, category, pageId } = location.state || {};
    const [pageUserData, setPageUserData] = useState({});
    const [userData, setUserData] = useState({});
    const [userQuestions, setUserQuestions] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [userComments, setUserComments] = useState([]);
    const [chatOpen, setChatOpen] = useState(false);
    const [value, setValue] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [currentQuestionPage, setCurrentQuestionPage] = useState(1);
    const [currentAnswerPage, setCurrentAnswerPage] = useState(1);
    const [currentCommentPage, setCurrentCommentPage] = useState(1);
    const itemsPerPage = 5;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // ページネーションのための関数
    const navigateToAnswer = (questionId, userName) => {
        navigate('/answer', {
            state: {
                userId: userId,
                questionUserId: userName,
                questionId: questionId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
                categories: category
            },
            replace: true,
        });
    };

    // ユーザーデータとユーザーのコンテンツを取得
    useEffect(() => {
        // ユーザーデータを取得
        const fetchUserData = async () => {
            try {
                const userResponse = await axios.post(`/backend/getuserdata`, {
                    user_id: userId,
                    withCredentials: true
                });
                setUserData(userResponse.data.user);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        // ページユーザーデータを取得
        const fetchPageUserData = async () => {
            try {
                const userResponse = await axios.post(`/backend/getuserdata`, {
                    user_id: pageId,
                    withCredentials: true
                });
                setPageUserData(userResponse.data.user);
            } catch (error) {
                console.error('Error fetching page user data:', error);
            }
        };

        // ユーザーのコンテンツを取得
        const fetchUserContent = async () => {
            try {
                // ユーザーの質問を取得
                const questionsResponse = await axios.post(`/backend/getuserquestions`, {
                    user_id: pageId,
                    withCredentials: true
                });
                // 質問を作成日時の降順でソート
                const sortedQuestions = questionsResponse.data.questions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setUserQuestions(sortedQuestions);
                // ユーザーの回答を取得
                const answersResponse = await axios.post(`/backend/getuseranswers`, {
                    user_id: pageId,
                    withCredentials: true
                });
                // 回答を作成日時の降順でソート
                const sortedAnswers = answersResponse.data.answers.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setUserAnswers(sortedAnswers);
                // ユーザーのコメントを取得
                const commentsResponse = await axios.post(`/backend/getusercomments`, {
                    user_id: pageId,
                    withCredentials: true
                });
                // コメントを作成日時の降順でソート
                const sortedComments = commentsResponse.data.comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setUserComments(sortedComments);
            } catch (error) {
                console.error('Error fetching user content:', error);
            }
        };

        // ユーザーデータとユーザーコンテンツの取得を行う
        fetchUserData();
        fetchPageUserData();
        fetchUserContent();
    }, [pageId]);

    // ページネーションのための関数
    const paginateData = (data, currentPage) => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        return data.slice(indexOfFirstItem, indexOfLastItem);
    };

    // ページネーションのための関数
    const renderPaginatedItems = (items, ItemComponent, currentPage, setCurrentPage) => (
        <>
            {paginateData(items, currentPage).map((item) => (
                <ItemComponent
                    key={item.id}
                    question={item}
                    answer={item}
                    comment={item}
                    userData={userData}
                    pageUserData={pageUserData}
                    navigateToAnswer={navigateToAnswer}
                />
            ))}
            <PaginationControls
                totalItems={items.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
            />
        </>
    );

    // タブのコンテンツを表示するための関数
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    
    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={category}
                />
                <MenuButtons
                    navigate={navigate}
                    userId={userId}
                    buttonType={'ユーザー'}
                    notificationCount={notificationCount}
                    notifications={notifications}
                    userData={userData}
                    categories={category}
                />
            </Box>

            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ flexGrow: 1, backgroundColor: '#F2F2F2' }}
            >
                <Grid
                    item xs={12}
                    sx={{ width: '80%', mt: 5 }}
                >
                    <Box sx={{ p: 2, pl: '1em', pr: '6em', width: '100%' }}>
                        <Box
                            sx={{
                                my: 0,
                                p: 4,
                                width: '100%',
                                backgroundColor: 'white',
                                borderRadius: 4,
                                boxShadow: 1
                            }}
                        >
                            <Grid container spacing={3} direction="column" alignItems="center">
                                <Container maxWidth="md" sx={{ mt: 4 }}>
                                    {/* ユーザープロフィール */}
                                    <Grid item xs={12} sx={{ marginBottom: '40px' }}>
                                        <Card elevation={3} sx={{
                                            padding: '20px',
                                            margin: '20px 0',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            backgroundColor: '#ffffff',
                                        }}>
                                            <Avatar
                                                src={pageUserData.icon && pageUserData.icon !== "null" ? pageUserData.icon : ""}
                                                alt="User icon"
                                                sx={{ width: 120, height: 120, mb: 2 }}
                                            />
                                            <Typography variant="h5" gutterBottom>
                                                {pageUserData.name}
                                            </Typography>
                                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                                {pageUserData.affiliation}
                                            </Typography>
                                            <Divider sx={{ width: '100%', mb: 2 }} />
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
                                                {typeof pageUserData.skill === 'string'
                                                    ? pageUserData.skill.replace(/^\{|\}$/g, '').split(',').map((skill, index) => (
                                                        <Chip
                                                            key={index}
                                                            icon={<CheckIcon />}
                                                            label={skill.trim()}
                                                            color="primary"
                                                            variant="outlined"
                                                            sx={{ mb: 1 }}
                                                        />
                                                    ))
                                                    : <Chip label="No skills provided" />
                                                }
                                            </Box>
                                        </Card>
                                    </Grid>

                                    <Card elevation={3} sx={{ padding: '20px', margin: '20px 0' }}>
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs
                                                    value={value}
                                                    onChange={handleChange}
                                                    aria-label="simple tabs example"
                                                    sx={{
                                                        '.MuiTabs-indicator': {
                                                            backgroundColor: 'primary.main',
                                                        },
                                                        '.MuiTab-root': {
                                                            textTransform: 'none',
                                                            margin: '0 12px',
                                                            '&:hover': {
                                                                backgroundColor: 'action.hover',
                                                            },
                                                            '&.Mui-selected': {
                                                                color: 'primary.main',
                                                                fontWeight: 'bold',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Tab label="投稿した質問" {...a11yProps(0)} />
                                                    <Tab label="投稿した回答" {...a11yProps(1)} />
                                                    <Tab label="投稿したコメント" {...a11yProps(2)} />
                                                </Tabs>
                                            </Box>

                                            {/* ユーザーの投稿した質問 */}
                                            <TabPanel value={value} index={0}>
                                                {renderPaginatedItems(userQuestions, QuestionItem, currentQuestionPage, setCurrentQuestionPage)}
                                            </TabPanel>

                                            {/* ユーザーの投稿した回答 */}
                                            <TabPanel value={value} index={1}>
                                                {renderPaginatedItems(userAnswers, AnswerItem, currentAnswerPage, setCurrentAnswerPage)}
                                            </TabPanel>

                                            {/* ユーザーの投稿したコメント */}
                                            <TabPanel value={value} index={2}>
                                                {renderPaginatedItems(userComments, CommentItem, currentCommentPage, setCurrentCommentPage)}
                                            </TabPanel>
                                        </Box>
                                    </Card>
                                </Container>

                                {/* チャットボット */}
                                <Box sx={{ position: 'fixed', right: 15, bottom: 15, zIndex: 1000 }}>
                                    {chatOpen ? (
                                        <ChatWindow userId={userId} onClose={() => setChatOpen(false)} />
                                    ) : (
                                        <ChatButton onClick={() => setChatOpen(true)} />
                                    )}
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
}