import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import { React, useEffect, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from 'react-router-dom';

// 画面が再描画される度に回答を取得するようにする
function AnswerList({
    questionId,
    userId,
    setAnswerCommentEditorProps,
    handleEditing,
    answer,
    userData,
    fileName,
    answerUserId,
    is_deleted,
    resolved,
    notificationCount,
    notifications,
    categories
}) {
    const [answerName, setAnswerNname] = useState([]);
    const [answerUserIcon, setAnswerUserIcon] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [openToggleVisibilityDialog, setOpenToggleVisibilityDialog] = useState(false);
    const navigate = useNavigate();
    const isUserAnonymous = answer.is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';

    const displayUserName = answer.is_anonymous
        ? isAdmin
            ? `${answerName || answerUserId} (匿名投稿)`
            : '匿名ユーザー'
        : answerName || answerUserId;
    // ダイアログ表示状態
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    // 削除ダイアログを開く
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    // 削除ダイアログを閉じる
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    // 削除処理
    const handleDelete = () => {
        handleDeleteAnswer();
        handleCloseDeleteDialog();
    };

    // 表示/非表示切り替えダイアログを開く
    const handleOpenToggleVisibilityDialog = () => {
        setOpenToggleVisibilityDialog(true);
    };

    // 表示/非表示切り替えダイアログを閉じる
    const handleCloseToggleVisibilityDialog = () => {
        setOpenToggleVisibilityDialog(false);
    };

    // 表示/非表示切り替え処理
    const toggleVisibility = async (answerId, isDeleted) => {
        try {
            // 表示/非表示切り替えのAPIリクエストを送信
            await handleToggleVisibility(answerId, isDeleted);
            console.log("質問の表示状態が切り替わりました");
        } catch (error) {
            console.error("表示/非表示の切り替え中にエラーが発生しました:", error);
        } finally {
            // 処理が終了したらダイアログを閉じる
            handleCloseToggleVisibilityDialog();
            window.location.reload();
        }
    };
    // 回答を削除する関数
    const handleDeleteAnswer = async () => {
        setIsDeleting(true);
        setDeleteMessage('');

        try {
            const res = await axios.post(`/backend/deleteanswer`, {
                answerId: answer.id,
                withCredentials: true
            });

            if (res.status === 200) {
                setDeleteMessage('回答が削除されました');
                // 削除成功後にページをリロード
                window.location.reload();
            }
        } catch (error) {
            console.error('回答の削除中にエラーが発生しました:', error);
            setDeleteMessage('回答の削除中にエラーが発生しました');
        } finally {
            setIsDeleting(false);
        }
    };

    // 質問の表示/非表示を切り替える関数
    const handleToggleVisibility = async (answerId, isDeleted) => {
        try {
            // APIリクエストを送信
            const response = await axios.post('/backend/toggle-visibility-answer', {
                answerId,
                isHidden: isDeleted
            });

            // レスポンスが成功した場合
            if (response.status === 200) {
                console.log('回答の表示状態が切り替わりました');
            }
        } catch (error) {
            console.error('表示/非表示の切り替え中にエラーが発生しました', error);
        }
    };

    // ユーザー画面に移動する
    const moveUsers = () => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合は何もしない
            return;
        }
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: answerUserId
            }
        })
    };

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        async function getusers() {
            try {
                const res = await axios.post(
                    `/backend/getusers`,
                    {
                        user_id: answerUserId,
                        withCredentials: true
                    }
                );
                setAnswerNname(res.data.name);
                setAnswerUserIcon(res.data.icon);
            } catch (error) {
                console.error('ユーザー情報の取得に失敗しました。', error);
            }
        }
        getusers();
    }, [answerUserId, isUserAnonymous]);

    // 既存のfile_nameと新しい画像・ファイルリストを表示するロジック
    const renderFileLinks = (answer) => {
        const links = [];

        // 古いfile_nameが存在する場合はリンクを追加
        if (answer.file_name) {
            links.push(
                <div key={`old-file-${answer.id}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(answer.file_name);
                    }}>{extractFileName(answer.file_name)}</a>
                </div>
            );
        }

        answer.images.forEach((image, index) => {
            links.push(
                <div key={`image-${answer.id}-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(image.file_name);
                    }}>{extractFileName(image.file_name)}</a>
                </div>
            );
        });

        // 新しいその他のファイルのリンクを追加
        answer.files.forEach((file, index) => {
            links.push(
                <div key={`file-${answer.id}-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(file.file_name);
                    }}>{extractFileName(file.file_name)}</a>
                </div>
            );
        });

        return links;
    };


    // 必要に応じて、AnswerCommentEditor のプロパティを設定
    const handleClick = (answer_id, replied_comment_id) => {
        setAnswerCommentEditorProps({
            Postfunction: PostComments,
            answer_id: answer_id,
            replied_comment_id: replied_comment_id
        });
    }

    // 入力されたブロックをマークアップとして受け取る
    const getContent = (editorState) => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const markup = stateToHTML(contentState);
        return {
            raw: JSON.stringify(rawContentState),
            markup: markup
        };
    }

    // 入力されたブロックをjsonとして受け取る
    const getTextBlocksAsJson = (editorState) => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const textBlocks = rawContentState.blocks.map(block => block.text);
        return textBlocks.join('');
    };

    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }
    
            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }
    
            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }
    
            code {
                font-family: 'Courier New', Courier, monospace;
            }
    
            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };


    // 入力された質問をサーバーを介してDBに保存する
    async function PostComments(answer_id, replied_comment_id, editorStateCommnets, userData) {
        const { raw, markup } = getContent(editorStateCommnets);
        const textBlocksJson = getTextBlocksAsJson(editorStateCommnets);

        // コメントを送信
        try {
            const res = await axios.post(`/backend/comment`,
                {
                    userId: userId,
                    question_id: questionId,
                    replied_comment_id: replied_comment_id,
                    answer_id: answer_id,
                    comment: textBlocksJson,
                    markup: markup,
                    withCredentials: true
                })
            console.log(res)
        } catch (error) {
            console.error('データ送信中にエラーが発生しました:', error);
        }
        // 画面を更新
        window.location.reload();
    };

    // ファイルをダウンロードする関数
    const downloadFile = async () => {
        console.log('ダウンロードするファイル:', fileName);
        // ファイルをダウンロード
        try {
            const response = await axios.post(
                `/backend/download`,
                {
                    fileName: fileName
                },
                {
                    withCredentials: true,
                    responseType: 'arraybuffer'
                }
            );
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', extractFileName(fileName));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('File download failed:', error);
        }
    };

    // ファイル名を抽出する関数
    function extractFileName(str) {
        const regex = /.*?_([^_]+\.\w+)$/;
        const match = str.match(regex);
        return match ? match[1] : str;
    }

    return (
        <div>
            <Grid key={answer.id} container direction="column" wrap="nowrap" spacing={2}>
                <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Avatar onClick={moveUsers} style={{ cursor: isUserAnonymous ? 'default' : 'pointer' }}>
                                {isUserAnonymous ? (
                                    <PersonIcon />
                                ) : answerUserIcon !== "null" ? (
                                    <img src={answerUserIcon} style={{ maxWidth: '100%', height: 'auto' }} alt="user icon" />
                                ) : (
                                    <PersonIcon />
                                )}
                            </Avatar>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h6" marginRight="10px">
                                {displayUserName}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* 削除ボタン（管理者のみ） */}
                                {userData.role === 'admin' && (
                                    <Tooltip title="回答を削除">
                                        <IconButton onClick={handleOpenDeleteDialog} disabled={isDeleting} sx={{ color: 'red', '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: 'darkred', } }}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {isDeleting && <CircularProgress size={24} />}

                                {/* 削除確認ダイアログ */}
                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>{"本当に削除しますか？"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>この操作は元に戻せません。選択した回答を削除してもよろしいですか？</DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">キャンセル</Button>
                                        <Button onClick={handleDelete} color="primary" autoFocus>はい</Button>
                                    </DialogActions>
                                </Dialog>

                                {/* 表示/非表示切り替えボタン */}
                                {(userData.role === 'admin' || (answerUserId === userData.id && resolved === false)) && (
                                    <Tooltip title={is_deleted ? "回答を表示" : "回答を非表示"}>
                                        <IconButton
                                            onClick={handleOpenToggleVisibilityDialog}
                                            sx={{ color: is_deleted ? 'gray' : 'blue', '&:hover': { backgroundColor: is_deleted ? 'rgba(128, 128, 128, 0.1)' : 'rgba(0, 0, 255, 0.1)', color: answer.is_deleted ? 'darkgray' : 'darkblue', } }}>
                                            {is_deleted ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </Tooltip>
                                )}

                                {/* 表示/非表示切り替え確認ダイアログ */}
                                <Dialog open={openToggleVisibilityDialog} onClose={handleCloseToggleVisibilityDialog}>
                                    <DialogTitle>{"回答の表示状態を変更しますか？"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>この操作により、回答の表示状態が変更されます。続行してもよろしいですか？</DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseToggleVisibilityDialog} color="primary">キャンセル</Button>
                                        <Button onClick={() => toggleVisibility(answer.id, is_deleted)} color="primary" autoFocus>はい</Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs zeroMinWidth>
                    <div
                        key={answer.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        {/* 回答の内容 */}
                        <DisplayMarkup html={answer.html_text} />
                        {/* 添付ファイルがある場合のみリンクを表示 */}
                        {<div>{renderFileLinks(answer)}</div>}
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/* コメントボタン */}
                            <Button variant="outlined" size="small" onClick={() => { handleEditing(answer.id); handleClick(answer.id, 0); }}>
                                コメント
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default AnswerList;
