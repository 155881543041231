import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


function QuestionListItem({
    userId,
    question_id,
    title,
    is_deleted,
    date,
    userName,
    resolved,
    userData,
    notificationCount,
    notifications,
    categories,
    is_anonymous
}) {
    const navigate = useNavigate();
    const [tags, setTags] = useState([]);
    const [questionName, setQuestionName] = useState('');
    const [questionUserId, setQuestionUserId] = useState([]);
    const [questionUserIcon, setQuestionUserIcon] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [openToggleVisibilityDialog, setOpenToggleVisibilityDialog] = useState(false);
    const isUserAnonymous = is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';

    // 管理者の場合、投稿者名と「匿名投稿」を表示
    const displayUserName = is_anonymous
        ? isAdmin
            ? `${questionName || questionUserId} (匿名投稿)`
            : '匿名ユーザー'
        : questionName || questionUserId;

    // ダイアログ表示状態
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    // 削除ダイアログを開く
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    // 削除ダイアログを閉じる
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    // 削除処理
    const handleDelete = () => {
        handleDeleteQuestion();
        handleCloseDeleteDialog();
    };

    // 表示/非表示切り替えダイアログを開く
    const handleOpenToggleVisibilityDialog = () => {
        setOpenToggleVisibilityDialog(true);
    };

    // 表示/非表示切り替えダイアログを閉じる
    const handleCloseToggleVisibilityDialog = () => {
        setOpenToggleVisibilityDialog(false);
    };

    // 表示/非表示切り替え処理
    const toggleVisibility = async (questionId, isDeleted) => {
        try {
            // 表示/非表示切り替えのAPIリクエストを送信
            await handleToggleVisibility(questionId, isDeleted);
            console.log("質問の表示状態が切り替わりました");
        } catch (error) {
            console.error("表示/非表示の切り替え中にエラーが発生しました:", error);
        } finally {
            // 処理が終了したらダイアログを閉じる
            handleCloseToggleVisibilityDialog();
            window.location.reload();
        }
    };

    // 日付をフォーマットする
    function formatDate(dateString) {
        // GMT を +0900 に置き換える
        const adjustedDateString = dateString.replace('GMT', '+0900');

        // 日付を解釈
        const date = new Date(adjustedDateString);

        // 日本のフォーマットで表示
        return date.toLocaleString('ja-JP', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    }

    // 画面遷移処理
    const navigateToAnswer = (questionId, userName) => {
        console.log("回答画面に遷移します");
        navigate('/answer', {
            state: {
                userId: userId,
                questionUserId: userName,
                questionId: questionId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
                categories: categories

            },
            replace: true,
        });
    };

    // 画面遷移処理
    const navigateToResolved = (questionId, userName) => {
        console.log("解決画面に遷移します");
        navigate('/resolved', {
            state: {
                userId: userId,
                questionUserId: userName,
                questionId: questionId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
                categories: categories
            },
            replace: true,
        });
    };

    // ユーザー画面に移動する
    const moveUsers = () => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合は何もしない
            return;
        }
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: userName
            }
        })
    };

    // タグを取得する
    useEffect(() => {
        console.log('タグを取得します')
        async function getTags() {
            const res = await axios.post(
                `/backend/gettags`,
                {
                    questionId: question_id,
                    withCredentials: true
                }
            );
            console.log(res)
            setTags(res.data);
        }
        getTags();
    }, []);

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        console.log('ユーザー情報を取得する')
        async function getusers() {
            const res = await axios.post(
                `/backend/getusers`,
                {
                    user_id: userName,
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestionName(res.data.name);
            setQuestionUserId(res.data.id);
            setQuestionUserIcon(res.data.icon);
        }
        getusers();
    }, [userName, isUserAnonymous]);

    // 質問を削除する関数
    const handleDeleteQuestion = async () => {
        setIsDeleting(true);
        setDeleteMessage('');

        try {
            const res = await axios.post(`/backend/deletequestion`, {
                questionId: question_id,
                withCredentials: true
            });

            if (res.status === 200) {
                setDeleteMessage('質問が削除されました');
                // 削除成功後にページをリロード
                window.location.reload();
            }
        } catch (error) {
            console.error('質問の削除中にエラーが発生しました:', error);
            setDeleteMessage('質問の削除中にエラーが発生しました');
        } finally {
            setIsDeleting(false);
        }
    };

    // 質問の表示/非表示を切り替える関数
    const handleToggleVisibility = async (questionId, isDeleted) => {
        try {
            // APIリクエストを送信
            const response = await axios.post('/backend/toggle-visibility-question', {
                questionId,
                isHidden: isDeleted
            });

            // レスポンスが成功した場合
            if (response.status === 200) {
                console.log('質問の表示状態が切り替わりました');
            }
        } catch (error) {
            console.error('表示/非表示の切り替え中にエラーが発生しました', error);
        }
    };


    return (
        <Box sx={{ p: 2, pl: '1em', pr: '6em', width: '100%' }}>
            <Box
                sx={{
                    my: 0,
                    p: 4,
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: 4,
                    boxShadow: 1
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography
                            variant="h6"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (resolved) {
                                    navigateToResolved(question_id, userName);
                                } else {
                                    navigateToAnswer(question_id, userName);
                                }
                            }}
                            color="primary"
                        >
                            {title}
                            {resolved && (
                                <Chip label="解決済み" color="success" size="small" sx={{ ml: 1, mt: -1 }} />
                            )}
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {/* 削除ボタン（管理者のみ） */}
                            {userData.role === 'admin' && (
                                <Tooltip title="質問を削除">
                                    <IconButton
                                        onClick={handleOpenDeleteDialog}
                                        disabled={isDeleting}
                                        sx={{
                                            color: 'red',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                                color: 'darkred',
                                            }
                                        }}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {isDeleting && <CircularProgress size={24} />}

                            {/* 削除確認ダイアログ */}
                            <Dialog
                                open={openDeleteDialog}
                                onClose={handleCloseDeleteDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"本当に削除しますか？"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        この操作は元に戻せません。選択した質問を削除してもよろしいですか？
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseDeleteDialog} color="primary">
                                        キャンセル
                                    </Button>
                                    <Button onClick={handleDelete} color="primary" autoFocus>
                                        はい
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/* 質問表示/非表示切り替えボタン（管理者、または未解決の質問の投稿者） */}
                            {(userData.role === 'admin' || (userName === userData.id && resolved === false)) && (
                                <Tooltip title={is_deleted ? "質問を表示" : "質問を非表示"}>
                                    <IconButton
                                        onClick={handleOpenToggleVisibilityDialog}
                                        sx={{
                                            color: is_deleted ? 'gray' : 'blue',
                                            '&:hover': {
                                                backgroundColor: is_deleted ? 'rgba(128, 128, 128, 0.1)' : 'rgba(0, 0, 255, 0.1)',
                                                color: is_deleted ? 'darkgray' : 'darkblue',
                                            }
                                        }}
                                    >
                                        {is_deleted ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </Tooltip>
                            )}

                            {/* 表示/非表示切り替え確認ダイアログ */}
                            <Dialog
                                open={openToggleVisibilityDialog}
                                onClose={handleCloseToggleVisibilityDialog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">{"質問の表示状態を変更しますか？"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        この操作により、質問の表示状態が変更されます。続行してもよろしいですか？
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseToggleVisibilityDialog} color="primary">
                                        キャンセル
                                    </Button>
                                    <Button onClick={() => toggleVisibility(question_id, is_deleted)} color="primary" autoFocus>
                                        はい
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        {tags && tags.map((tag, index) => (
                            <Chip key={index} label={tag} sx={{ mx: 0.5, my: 1 }} />
                        ))}
                    </Grid>

                    <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Avatar
                                            onClick={moveUsers}
                                            style={{ cursor: isUserAnonymous ? 'default' : 'pointer' }}
                                            sx={{ width: 24, height: 24 }}
                                        >
                                            {isUserAnonymous ? (
                                                <PersonIcon fontSize="small" />
                                            ) : questionUserIcon !== "null" ? (
                                                <img src={questionUserIcon} alt="User icon" style={{ height: 24, width: 24 }} />
                                            ) : (
                                                <PersonIcon fontSize="small" />
                                            )}
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" sx={{ ml: 1 }}>
                                        {displayUserName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Typography variant="body2">
                                投稿日時: {formatDate(date)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default QuestionListItem;
